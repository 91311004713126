import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { REACT_BASE_PATH } from "../../../../api";

const OrderDetails = () => {
    const location = useLocation();
    const orderId = location.state || {};
    const [orderData, setOrederData] = useState('')
    const activity = [
        { status: 'pending', text: 'Your order is not been confirmed yet', date: '02 Sept, 2024, 11:29 AM' },
        { status: 'confirmed', text: 'Your order has been confirmed', date: '02 Sept, 2024, 11:29 AM' },
        { status: 'dispatched', text: 'Your order been confirmed yet', date: '02 Sept, 2024, 11:29 AM' },
        { status: 'out for delivery', text: 'Your order is not been confirmed yet', date: '02 Sept, 2024, 11:29 AM' },
        { status: 'delivered', text: 'Your order is not been confirmed yet', date: '02 Sept, 2024, 11:29 AM' },
    ]

    const currentIndex = activity.findIndex(item => item.status === orderData?.status);

    useEffect(() => {
        getOrderDetails()
    }, [])

    const getOrderDetails = async () => {
        const vendor = JSON.parse(localStorage.getItem('vendor'))
        const { vendorid, role } = vendor;
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/GetOrderByOrderId`, {
                "orderId": orderId,
                "vendorId": role == 'admin' ? "0" : vendorid
            });
            if (response.data.status === 200) {
                setOrederData(response.data.data)
            } else {
                console.log('error');

            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }


    return (
        <>
            <div>
                <section className="custom-cart-panel mb-3">
                    <div className="container-fuild">
                        <div className="row">
                            <div className="vendor-top col-12">
                                <h5 className="title-v">Orders ID : {orderData?._id}</h5>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="row">
                    <div className="col-md-8">
                        <section className="custom-cart-panel mb-3">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Orders Details</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="mlr-10 table-responsive mb-4">
                                <table className="tableGap">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Products</th>
                                            <th>Color</th>
                                            <th>Price</th>
                                            <th>QTY</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderData?.items?.length > 0 && orderData?.items.map((data, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>{data?.productId?.product_name}</td>
                                                    <td>{data?.color}</td>
                                                    <td>{data?.productId?.currency_type == 'inr' ? 'Rs.' : '$'} {data?.productId?.price}</td>
                                                    <td>
                                                        {data?.quantity}
                                                    </td>
                                                    <td>{data?.price * data?.quantity}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            <div className="orderPrice">
                                {orderData?.items?.length > 0 && (
                                    <>
                                        <p>Sub total: {orderData.items[0].currency_type === 'inr' ? 'Rs.' : '$'} {(orderData.totalPrice - 7.00).toFixed(2)}</p>
                                        <p>Discount: {orderData.items[0].currency_type === 'inr' ? 'Rs.' : '$'} {orderData.offerValue}</p>
                                        <p>TAX: {orderData.items[0].currency_type === 'inr' ? 'Rs.' : '$'} 07.00</p>
                                        <p>Total: {orderData.items[0].currency_type === 'inr' ? 'Rs.' : '$'} {orderData.totalPrice.toFixed(2)}</p>
                                    </>
                                )}

                            </div>
                        </section>
                        <section className="custom-cart-panel">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Shipping Activity</h5>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul class="timeline pb-0 mb-0">
                                    {activity.map((item, index) => {
                                        return (
                                            <li class={`timeline-item timeline-item-transparent ${index <= currentIndex ? 'border-primary' : 'border-left-dashed'}`}>
                                                <span class={`timeline-point timeline-point-primary ${index <= currentIndex ? '' : 'timeline-point-disable'}`}></span>
                                                <div class="timeline-event">
                                                    <div class="timeline-header">
                                                        <h6 class="mb-0">{item.status}</h6>
                                                        <small class="text-muted">Tuesday 11:29 AM</small>
                                                    </div>
                                                    <p class="mt-3">{item.text}</p>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </section>
                    </div>
                    <div className="col-md-4">
                        <section className="custom-cart-panel mb-3">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Customer Details</h5>
                                    </div>
                                </div>
                                <div className="addressBilling mb-2">
                                    <p><strong>Name:-</strong> {orderData?.userId?.first_name} {orderData?.userId?.last_name}</p>
                                    <p><strong>Customer ID:-</strong> {orderData?.userId?._id}</p>
                                    <hr />
                                    <h5>Contact Info</h5>
                                    <p><strong>Email ID:-</strong> {orderData?.userId?.email}</p>
                                </div>
                            </div>
                        </section>
                        <section className="custom-cart-panel mb-3">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Shipping Address</h5>
                                    </div>
                                </div>
                                <div className="addressBilling mb-2">
                                    <p>{orderData?.shippingAddress?.locality}</p>
                                    <p>{orderData?.shippingAddress?.landmark}</p>
                                    <p>{orderData?.shippingAddress?.address_detail}</p>
                                    <p>{orderData?.shippingAddress?.city} {orderData?.shippingAddress?.state},
                                        {orderData?.shippingAddress?.country}, {orderData?.shippingAddress?.pin_code}</p>
                                </div>
                            </div>
                        </section>
                        <section className="custom-cart-panel mb-3">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Billing Address</h5>
                                    </div>
                                </div>
                                <div className="addressBilling mb-2">
                                    <p>{orderData?.shippingAddress?.locality}</p>
                                    <p>{orderData?.shippingAddress?.landmark}</p>
                                    <p>{orderData?.shippingAddress?.address_detail}</p>
                                    <p>{orderData?.shippingAddress?.city} {orderData?.shippingAddress?.state},
                                        {orderData?.shippingAddress?.country}, {orderData?.shippingAddress?.pin_code}</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetails;