import { KRIZAAR_LIVE, REACT_BASE_PATH } from "./api"

// https://krizaar.com/php/sdk/check-outh.php
export const BASE_URL = 'https://krizaar.com/api/admin'
export const BASE_URL_AFFILATE = 'https://krizaar.com/api/affiliate'
export const IMAGE_BASE = 'https://krizaar.com'

// export const BASE_URL = 'http://localhost:5000/admin'
// export const BASE_URL_AFFILATE = 'http://localhost:5000/api/affiliate'
// export const _get_image_path = 'http://localhost:5000'

export const _getProductCategoryList = `${IMAGE_BASE}/api/product/productcategory`
export const _fetchCategoryList = `${REACT_BASE_PATH}/product/getCategoryList`;
export const _saveProductData = `${REACT_BASE_PATH}/product/save/product`
export const _existProductData = `${REACT_BASE_PATH}/product/get/exist/product`

// aliExpress 
export const getSettingDataURL = `${REACT_BASE_PATH}/admin/get/php/setting`
export const updateProductByCategoryURL = `${REACT_BASE_PATH}/admin/get/updateSetting`
export const _feedNameURL = `${KRIZAAR_LIVE}/feedname.php`;
export const _fetchProductData = `${KRIZAAR_LIVE}/get-product-api.php`
export const getAccessTokenURL = `${KRIZAAR_LIVE}/check-outh.php`
export const get_skuid_of_productURL = `${KRIZAAR_LIVE}/product.php`


// affilates urls
export const _assignCategoryURL = `${BASE_URL}/asign/category`
export const _fetchUsers = `${BASE_URL}/get/alluser`;
export const _updateUserStatusURL = `${BASE_URL}/status/update`;
export const _deleteUserStatusURL = `${BASE_URL}/delete/category`;
export const _deleteUser = `${BASE_URL}/delete/user`;
export const _getProductStateURL = `${BASE_URL}/get/product/status`;
export const _updateUserDetailURL = `${BASE_URL}/update/user`;
export const _getSpecificUsesr = `${BASE_URL}/get/specific/user`
export const _get_krizaar_orders = `${BASE_URL}/get/freight/data`
export const _update_kyc = `${BASE_URL}/update/kyc/record`



export const _affilate_signup = `${BASE_URL_AFFILATE}/user/register`
export const _getProductByCategory = `${BASE_URL_AFFILATE}/get/product/list`
export const _generateLinkUrl = `${BASE_URL_AFFILATE}/generate/product/share/link`
export const _getProfile = `${BASE_URL_AFFILATE}/get/profile`
export const _get_task_affilate = `${BASE_URL_AFFILATE}/get/task`
export const _complete_task = `${BASE_URL_AFFILATE}/complete/task`
export const _get_all_history = `${BASE_URL_AFFILATE}/get/task/history`
export const _save_affilate_user_state_url = `${BASE_URL_AFFILATE}/save/affiliate/user/product/status`
export const _getAssignedCategories = `${BASE_URL_AFFILATE}/get/listed/category`
export const _get_task_collection = `${BASE_URL_AFFILATE}/get/task/collection`
export const _getAllShareLink = (ID) =>  `${BASE_URL_AFFILATE}/get/all/created/links/${ID}`
export const _getActiveCategory = `${BASE_URL_AFFILATE}/get/listed/category`


// admin
export const _update_task_history = `${BASE_URL}/update/task/history`
export const _get_image_path = `https://krizaar.com/api/uploads/`

// airdrop
export const _create_task_lable_Url = `${BASE_URL}/create/task/table`
export const _create_task_Url = `${BASE_URL}/create/task`
export const _get_task_lable = `${BASE_URL}/get/task/table`
export const _get_task = `${BASE_URL}/get/task`
export const _update_task = `${BASE_URL}/update/task`
export const _update_task_table = `${BASE_URL}/update/task/table`
export const _delete_task = `${BASE_URL}/delete/task`
export const _delete_task_table = `${BASE_URL}/delete/task/table`
export const _get_task_history = `${BASE_URL}/get/task/history`

// shipping
export const _save_shipping_details = `https://krizaar.com/php/sdk/freight.php`
export const _save_shipping_address_detail = `${REACT_BASE_PATH}/auth/user/shipping/data`


// export const _update_task_history = `${BASE_URL}/update/task/history`