import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const ReturnRefund = () => {
  useEffect(() => {
    //import('./WebPanelRoutes.css');
  }, [])
  return (
    <>
      <section>
        <div className="container">
          <div className='terms-of-service my-5'>
            <h1 className="my-3">Krizaar Return & Refund Policy</h1>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div style={{ textAlign: 'justify' }}>At Krizaar, customer satisfaction is our top priority. If you are not completely satisfied with your purchase, we are here to help.</div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Returns</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>You have 30 calendar days to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it. The item must also be in the original packaging. Please include the receipt or proof of purchase with your return.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Refunds</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately inform you of the status of your refund after inspecting the item. If your return is approved, we will initiate a refund to your original method of payment. You will receive the credit within a certain amount of days, depending on your card issuer's policies.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Shipping</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>You will be responsible for paying your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Exchanges</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>If you received a defective or damaged item, we would be happy to exchange it for the same item. Please contact us at info@krizaar.com to arrange an exchange.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Contact Us</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>
                      <p>
                        If you have any questions about our Return & Refund Policy, please contact us atinfo@krizaar.com
                      </p>
                      <p>
                        Thank you for shopping with Krizaar. We value your business and are committed to providing you with the best customer service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReturnRefund;
