import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const CookiesPolicy = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className='terms-of-service my-5'>
            <h1 className="my-3">Krizaar Cookies Policy</h1>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-3 mt-1'>
                <div style={{ textAlign: 'justify' }}>Krizaar uses cookies, pixels, and similar technologies (collectively referred to as "cookies") to identify your browser or device, understand your preferences, and offer essential features and services. We use cookies for several purposes, including:
                  <ol>
                    <li><b>•	Recognizing You:</b> When you sign in, cookies help us recognize you, offer product recommendations, display personalized content, and enable features like 1-Click purchasing.</li>
                    <li><b>•	Tracking Preferences:</b> Cookies allow us to remember your preferences, such as whether you want to see interest-based ads. You can set these preferences in your account.</li>
                    <li><b>•	Managing Your Shopping Basket:</b> Cookies help keep track of items in your cart.</li>
                    <li><b>•	Improving Services:</b> We use cookies for research, diagnostics, and to enhance Krizaar’s content, products, and services.</li>
                    <li><b>•	Fraud Prevention:</b> Cookies assist in detecting and preventing fraudulent activities.</li>
                    <li><b>•	Enhancing Security:</b> Cookies improve the overall security of our services.</li>
                    <li><b>•	Interest-Based Ads:</b> We use cookies to deliver ads relevant to your interests on Krizaar and third-party sites. For more information, see our Interest-Based Ads policy.</li>
                    <li><b>•	Performance Reporting:</b> Cookies help measure and analyze the performance of our services.</li>
                  </ol>
                  <p className="mb-0">Krizaar’s cookies are essential for you to access certain features of the platform. If you block or reject cookies, you may not be able to add items to your shopping cart, proceed with checkout, or use services that require signing in.</p>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Third-Party Cookies</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>Approved third parties, such as search engines, analytics providers, social media networks, and advertisers, may also use cookies when you interact with Krizaar services. These cookies help deliver relevant ads and measure ad effectiveness.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Managing Cookies</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}><p>
                      You can manage cookies through your browser settings. Most browsers provide a 'Help' feature that explains how to block cookies, get notifications for new cookies, or adjust cookie preferences. If you block all cookies, certain features and services may not work, and you may need to manually update settings each time you visit.
                    </p>
                      <p>
                        For more details about the information we collect, please see our Privacy Notice.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CookiesPolicy;
