import { Box, Drawer, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { _create_task_lable_Url, _create_task_Url, _get_task_lable, _update_task, _update_task_table } from '../../../../../url';
import { getAdminId } from '../../../../../utills';

function CreateDrawer({ open, setOpen, for: actionFor, setRemout, action, editUser, resetData }) {
    const [labelName, setLabelName] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [description, setDescription] = useState('');
    const [task, settask] = useState('');
    const [url, seturl] = useState('');
    const [projectAmount, setprojectAmount] = useState('');
    const [labels, setLabels] = useState([]);

    console.log("labels", labels);

    useEffect(() => {
        if (action !== "Edit" && open) {
            if (actionFor === "CREATE_TASK_LABLE") {
                setLabelName("");
                setDescription("");
                setprojectAmount("");
            } else {

                resetData(() => {
                    settask("");
                    seturl("");
                })
            }
        }
    }, [open, actionFor])


    useEffect(() => {
        const fetchLabels = async () => {
            try {
                const response = await axios.post(_get_task_lable, { id: getAdminId() });
                setLabels(response.data.data); // Adjust based on your API response structure

            } catch (error) {
                console.error('Error fetching labels:', error);
                toast.error('Failed to fetch labels.');
            }
        };

        if (actionFor === 'CREATE_TASK') {
            fetchLabels();
        }
    }, [actionFor]);


    useEffect(() => {
        if (action === 'Edit' && actionFor === 'CREATE_TASK_LABLE') {
            setLabelName(editUser?.labelName);
            setDescription(editUser?.labelDescription);
            setprojectAmount(editUser?.projectAmount);
        } else if (action === 'Edit' && actionFor === 'CREATE_TASK') {
            settask(editUser?.taskName);
            seturl(editUser?.taskUrl);
        }
    }, [action, actionFor, editUser])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = actionFor === 'CREATE_TASK_LABLE'
            ? { labelName, projectAmount: +projectAmount, labelDescription: description, status: true }
            : { taskName: task, taskTable: selectedOption || editUser?.taskTabel?._id, taskUrl: url };



        if (action === 'Edit') {
            if (actionFor === 'CREATE_TASK') {
                formData.taskId = editUser?._id;
                try {
                    const response = await axios.post(_update_task, { id: getAdminId(), ...formData });
                    console.log('Form Submitted Successfully:', response.data);
                    toast.success('Updated successfully!');
                    resetForm();
                    setOpen(false); // Close the drawer after submission
                    setRemout(prev => !prev)
                } catch (error) {
                    console.error('Error submitting form:', error);
                    toast.error('Failed to update Please try again.');
                }
            } else {
                formData.tabelId = editUser?._id;
                try {
                    const response = await axios.post(_update_task_table, { id: getAdminId(), ...formData });
                    console.log('Form Submitted Successfully:', response.data);
                    toast.success('Updated Table successfully!');
                    resetForm();
                    setOpen(false); // Close the drawer after submission
                    setRemout(prev => !prev)
                } catch (error) {
                    console.error('Error submitting form:', error);
                    toast.error('Failed to update Please try again.');
                }
            }
        } else {
            try {
                const response = await axios.post(actionFor !== 'CREATE_TASK_LABLE' ? _create_task_Url : _create_task_lable_Url, { id: getAdminId(), ...formData });
                console.log('Form Submitted Successfully:', response.data);
                toast.success('Added submitted successfully!');
                resetForm();
                setOpen(false); // Close the drawer after submission
                setRemout(prev => !prev)
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Failed to submit the form. Please try again.');
            }
        }
    };

    const resetForm = () => {
        setLabelName('');
        setDescription('');
        setprojectAmount('');
        setSelectedOption('');
        settask('');
        seturl('');
    };

    return (
        <Drawer open={open} anchor="right">
            <Box sx={{ width: action === 'view' ? 500 : 350 }} role="presentation" className="p-4">
                {action === 'view' ? <h4 className='text-theme'>Project Details</h4> : <h4 className='text-theme'>{actionFor === 'CREATE_TASK_LABLE' ? `${action} Project` : `${action} Task`}</h4>}
                <form onSubmit={handleSubmit}>
                    {actionFor === 'CREATE_TASK_LABLE' && (
                        <React.Fragment>
                            <div className="form-group">
                                <label htmlFor="labelName">Project Name</label>
                                {
                                    action === 'view' ? (
                                        <div className="card p-2">
                                            {editUser?.labelName}
                                        </div>
                                    ) : (
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="labelName"
                                            value={labelName}
                                            onChange={(e) => setLabelName(e.target.value)}
                                            placeholder="Enter label name"
                                            required
                                        />
                                    )
                                }

                            </div>
                            <div className="form-group">
                                <label htmlFor="imageSelect">Project Cost<span className="text-danger">*</span></label>
                                {
                                    action === 'view' ? (
                                        <div className="card p-2">
                                            ${editUser?.projectAmount}
                                        </div>
                                    ) : (
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="projectAmount"
                                            value={projectAmount}
                                            onChange={(e) => setprojectAmount(e.target.value)}
                                            placeholder="Enter Project cost"
                                            required
                                        />
                                    )
                                }

                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Project Description<span className="text-danger">*</span></label>
                                {
                                    action === 'view' ? (
                                        <div className="card p-2">
                                            {editUser?.labelDescription}
                                        </div>
                                    ) : (
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            rows="4"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="Enter description"
                                            required
                                        />
                                    )
                                }

                            </div>
                        </React.Fragment>
                    )}
                    {actionFor === 'CREATE_TASK' && (
                        <React.Fragment>
                            <div className="form-group">
                                <label htmlFor="task">Task Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="task"
                                    value={task}
                                    // defaultValue={action==='Edit' ? editUser?.taskName : form}
                                    onChange={(e) => settask(e.target.value)}
                                    placeholder="Enter task name"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                {
                                    action === 'Edit' ? (
                                        <>
                                            <label htmlFor="imageSelect">Project Name<span className="text-danger">*</span></label>
                                            <div className="card p-2">
                                                {editUser?.taskTabel?.labelName}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <label htmlFor="imageSelect">Select Project Name<span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                id="imageSelect"
                                                value={selectedOption}
                                                onChange={(e) => setSelectedOption(e.target.value)}
                                                required
                                            >
                                                <option value="">Choose a Project Name</option>
                                                {labels.map((label) => (
                                                    <option key={label._id} value={label._id}>
                                                        {label.labelName}
                                                    </option>
                                                ))}
                                            </select>
                                        </>
                                    )
                                }

                            </div>
                            <div className="form-group">
                                <label htmlFor="amount">URL<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="url"
                                    value={url}
                                    onChange={(e) => seturl(e.target.value)}
                                    placeholder="Enter URL"
                                    required
                                />
                            </div>
                        </React.Fragment>
                    )}
                    <div className='d-flex justify-content-end mt-2 align-items-end gap-2'>
                        {action === 'view' ? null : <button type="submit" className="text-white btn-theam">{action === 'Edit' ? 'Save' : 'Add'}</button>}
                        <button type="button" onClick={() => setOpen(false)} className="btn btn-secondary">Close</button>
                    </div>
                </form>
            </Box>
        </Drawer>
    );
}

export default CreateDrawer;
