import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from "./Pagination";
import UpdateProductModal from "./UpdateProductModal";
import axios from "axios";
import { _assignCategoryURL, _deleteUserStatusURL, _fetchCategoryList, _fetchUsers, _getAssignedCategories, _getProductCategoryList, _updateUserStatusURL } from "../../../../url";
import toast from "react-hot-toast";
import { getAdminId, getAffialteToken } from "../../../../utills";
import DeleteConfirmationDialog from "./DeleteUserAskModal";

function AllProducts() {
  const [page, setPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [openConfirmModal, setopenConfirmModal] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const [categories, setCategory] = useState([]);
  const [users, setUser] = useState([]);
  const [categoryId, setcategoryId] = useState("");  // State for Category
  const [userId, setUserId] = useState("");      // State for Users
  const [percentage, setPercentage] = useState("");  
  const [isPending, setisPending] =  useState(false);
  const [isFetching, setIsFetching] =  useState(false);
  const [reMount, setRemount] = useState(false);
  const  [editUser, setEditUser] = useState(null);

   // pagination state
   const [selectedOption, setSelectedOption] = useState(10);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalItems, setTotalItems] = useState(0);
  
    // Fetch the category list on component mount
    useEffect(() => {
      const fetchCategories = async () => {
        try {
          const response = await axios.post(_getProductCategoryList, {parent_id: 0}); // Replace with actual URL
          // console.log(response.data);
          setCategory(response.data.data); // Assuming response.data contains the category list
        } catch (error) {
          toast.error("Failed to fetch categories");
          console.error("Error fetching categories:", error);
        }
      };
      fetchCategories();
    }, []);

    useEffect(() => {
      const fetUsers = async () => {
        setIsFetching(true);
        try {
          const response = await axios({
             method : 'post',
             url : _getAssignedCategories,
             headers : {
              'Authorization' : `Bearer ${getAffialteToken()}`
             },
             data : {
              id: getAdminId(),
              page : currentPage,
              pagesize:selectedOption
             }
          })// Replace with actual URL
          // console.log(response.data);
          const {status , data} = response.data;
          if(status === 200 && data) {
            setUser(data);
            setTotalItems(data?.length)
            setIsFetching(false); // Assuming response.data contains the category list
          }
        } catch (error) {
          toast.error("Failed to fetch categories");
          console.error("Error fetching categories:", error);
          setIsFetching(false);
        }
      };
      fetUsers();
    },[currentPage, selectedOption, reMount]);

  // State for Affiliate%
  // open modal
  const handleClickOpen = (userId) => {
    setOpen(true);
    setEditUser(userId)
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };
  
  // handle change page data size
  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);
    // setCurrentPage(1);
  };


   // Handler for form submission
   const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate if fields are filled
    if (!categoryId || !percentage) {
      // toast.error("Please fill all the fields");  // Optional: Display error
      return;
    }
    

    // Payload to be sent
    const payload = {
      id: getAdminId(),
      categoryId: categoryId,
      userId: userId,
      affiliatePercentage: +percentage,
    };
   

    _updateUser(payload);
  };

  // Handler for update user state
  const handleUpdateStatus = async (event,userId) => {
    setisPending(true);
    try {
      const response = await axios.post(_updateUserStatusURL, {
         id : getAdminId(),
         userId : userId,
         status : event.target.checked
      });
     
      console.log("handleUpdateStatus response", response.data)
      if (response.data.success) {
        toast.success("user status updated successfully");  // Optional: Display success
        // Reset form fields after successful submission
        setRemount(!reMount);
        setisPending(false);
      }
    } catch (error) {
      toast.error("Failed to update user status");  // Optional: Display error
      console.error("There was an error!", error);
      setisPending(false);
    }
  }
  

  // handler for delte user 
  const handleDeleteUser = async (user) => {
    setopenConfirmModal(true);
    setEditUser(user)
  }

  // Handler for update user common fun
  const _updateUser = async (payload) => {
    setisPending(true);
    try {
      const response = await axios.post(_assignCategoryURL, payload);
      
      console.log("_updateUser response", response.data.success)
      if (response?.data.success) {
        toast.success("Categories assigned to user successfully");  // Optional: Display success
        // Reset form fields after successful submission
        setcategoryId("");
        setUserId("");
        setPercentage("");
        setisPending(false);
        setRemount(!reMount)
      }
    } catch (error) {
      toast.error("Failed to assign category");  // Optional: Display error
      console.error("There was an error!", error);
      setisPending(false);
    }
  }


  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: pink[600],
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Size switch demo" } };



  // console.log("categoryId",categoryId)

  return (
    <> 
      {isPending && <LinearProgress color="secondary" /> }
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Affiliate Percentage</h5>
              <div className="button-right-side">
                <div className="addBrands">{/* <BrandPopup /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <form
              className="row justify-content-start  gap-2"
              onSubmit={handleSubmit}
            >
              <Box className="d-flex align-items-center gap-2 col-md-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoryId}
                    label="Category"
                    onChange={(e) => setcategoryId(e.target.value)}
                  >
                    {/* Add your options here */}
                    {categories?.map(({ _id, category_name }) => (
                      <MenuItem key={`catName-${_id}`} value={_id}>
                        {category_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <span>(All Category)</span>
              </Box>

              {/* <Box className="d-flex align-items-center gap-2 col-md-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Users</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userId}
                    label="Users"
                    onChange={(e) => setUserId(e.target.value)}
                  >
                   
                    {users?.map(({ _id, name, email }) => (
                      <MenuItem key={`affilte-user`} value={_id}>
                        {name} ({email})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <span>(Affiliate Users)</span>
              </Box> */}

              <Box className="d-flex align-items-center gap-2 col-md-3">
                <FormControl fullWidth>
                  <input
                    type="number"
                    value={percentage}
                    onChange={(e) => setPercentage(e.target.value)}
                    max="100"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Affiliate %"
                    required
                  />
                </FormControl>
                <span>(%)</span>
              </Box>

              <Button
                type="submit"
                variant="contained"
                size="medium"
                // style={{ background: "#C22D57" }}
                className="col-md-2"
                  disabled={!categoryId  || !percentage}
              >
                {"Add"}
              </Button>
            </form>
          </div>
        </div>
      </section>
      <section className="custom-cart-panel  mb-3">
        <div className="d-flex flex-row justify-content-between">
          <Box className="d-flex align-items-center gap-2 w-25">
            <FormControl fullWidth>
              <input
                // value={codeVal}
                // onChange={(e) => setcode(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Search Affiliates"
                required
              />
            </FormControl>
          </Box>
          <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
            <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
              <div
                className="dataTables_length mx-n2"
                id="DataTables_Table_0_length"
              >
                <label>
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    className="form-select"
                    id="entries"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="70">70</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
              <div className="mb-6">
                <button className="btn btn-theam mx-2" type="button">
                  <span>Export CSV</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="mlr-10 mb-4">
          {isFetching ? (
            "Fetching users..."
          ) : (
            <table className="tableGap">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Category</th>
                  <th>Affiliate(%)</th>
                  {/* <th>Status</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  users?.length === 0 && <p>No Date Found...</p>
                }
                {users?.length > 0  && users?.map((data, index) => (
                  <tr key={data?._id}>
                    <td>{index + 1}.</td>
                    <td>{data?.categoryId?.category_name}</td>
                    <td>{data?.affiliatePercentage}</td>
                    {/* <td>
                      <PinkSwitch
                        {...label}
                        checked={data?.isAffiliateUser}
                        onChange={(event) => handleUpdateStatus(event,data?._id)}
                      />
                    </td> */}
                    <td className="d-flex items-center">
                      <button
                        className="edit-btn"
                        onClick={() => handleClickOpen(data)}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="trash-icon disabledBtn" onClick={() => handleDeleteUser(data)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <Pagination
         selectedOption={selectedOption}
         currentPage={currentPage}
         setCurrentPage={setCurrentPage}
         totalItems={totalItems}
         setTotalItems={setTotalItems}
        />
        <UpdateProductModal
          open={open}
          handleClose={handleClose}
          title="Edit Product"
          categories={categories}
          isPending={isPending}
          editUser={editUser}
          _updateUser={_updateUser}
        />
        <DeleteConfirmationDialog 
          open={openConfirmModal}
          onClose={() => setopenConfirmModal(false)}
          editUser={editUser}
          setRemount={setRemount}
           title="Category Item"
        />
      </section>
    </>
  );
}

export default AllProducts;
