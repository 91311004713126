import React, { useEffect, useState } from "react";
import logo from "../img/krizaarlogo.jpeg";
import check from '../img/check.png'
import "./vendorRegistrationCss.css";
import { FileUploader } from "react-drag-drop-files";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import Webcam from "react-webcam";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Modal from "react-modal";
import { Country, State, City } from "country-state-city";
import SnackBar from "../components/AdminPanel/helper/SnackBar";
import axios from "axios";
import { REACT_BASE_PATH } from "../api";
import { useNavigate } from "react-router-dom";


const VendorRegistration = () => {
  const navigate = useNavigate();
  const vendorData = JSON.parse(localStorage.getItem('vendor'))
  const step_completed = localStorage.getItem('step_completed')
  const [isRegister, setIsRegister] = useState(!vendorData || step_completed == 0 ? true : false);
  const [isKyc, setIsKyc] = useState(step_completed == 1 ? true : false);
  const [isKYB, setIsKYB] = useState((step_completed == 2 && vendorData?.role == 'seller') ? true : false);
  const [isOtp, setisOtp] = useState(false);
  const [isSuccess, setisSuccess] = useState((step_completed == 2 && vendorData?.role !== 'seller') || step_completed == 3 ? true : false);
  const [Fname, setFname] = useState(vendorData?.first_name || "");
  const [Lname, setLname] = useState(vendorData?.last_name || "");
  const [Address, setAddress] = useState("");
  const [Email, setEmail] = useState(vendorData?.email || "");
  const [Captcha, setCaptcha] = useState("");
  const [MobileNumber, setMobileNumber] = useState(vendorData?.phone_number || "");
  const [Password, setPassword] = useState("");
  const [isPVisible, setisPVisible] = useState(true)
  const [isCVisible, setisCVisible] = useState(true)
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [vendorType, setvendorType] = useState(null)
  const [VendorId, setVendorId] = useState(vendorData?.vendorid)
  const [OTP, setOTP] = useState('')
  const [cPasswordType, setcPasswordType] = useState('password')
  const [passwordType, setpasswordType] = useState('password')
  const fileTypes = ["JPEG", "PNG", "GIF"];
  const fileTypesPDF = ["PDF"];
  const [IDfile, setIDFile] = useState(null);
  const [Addressfile, setAddressFile] = useState(null);
  const [CaptureImage, setCaptureImage] = useState(null);
  const webcamRef = React.useRef(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [CountryList, setCountryList] = useState([]);
  const [CountryCode, setCountryCode] = useState(null)
  const [StateList, setStateList] = useState([]);
  const [StateCode, setStateCode] = useState(null)
  const [CityList, setCityList] = useState([]);
  const [CityCode, setCityCode] = useState(null)
  const [PinCode, setPinCode] = useState('')
  const [NameAsChecked, setNameAsChecked] = useState(false);
  const [openTost, setopenTost] = useState(false);
  const [tostMessage, setostMessage] = useState('');
  const [CompanyName, setCompanyName] = useState('')
  const [CompanyTaxID, setCompanyTaxID] = useState('')
  const [CompanyAddress, setCompanyAddress] = useState('')
  const [type, setType] = useState('');
  const [Article, setArticle] = useState(null)
  const [AddressProof, setAddressProof] = useState(null)
  const [Bank, setBank] = useState(null)
  const [addAccountHolder, setAddAccountHolder] = useState([
    {
      id: 1,
      name: "",
      mobile: "",
      email: "",
      percantage: 0,
    },
  ]);
  const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: "user",
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: 0,
      padding: 0,
      backgroundColor: "#000",
    },
  };

  useEffect(() => {
    isRegister && loadCaptchaEnginge(6);
    const CountryList = Country.getAllCountries();
    setCountryList(CountryList);
  }, []);

  const _validateRegisterForm = () => {
    if (!Fname) {
      Toast('Please enter your first name')
      return false;
    }
    if (!Lname) {
      Toast('Please enter your last name')
      return false;
    }
    if (!Email) {
      Toast('Please enter your valid email')
      return false;
    }
    if (!MobileNumber || MobileNumber.length < 10) {
      Toast('Please enter your valid mobile number')
      return false;
    }
    if (!Password) {
      Toast('Please enter your valid password')
      return false;
    }
    if (Password.length < 8 || ConfirmPassword.length < 8) {
      Toast('Password should be 8 charectors')
      return false;
    }
    if (!ConfirmPassword) {
      Toast('Please enter your valid password')
      return false;
    }
    if (ConfirmPassword !== Password) {
      Toast('Password did not match')
      return false;
    }
    if (!validateCaptcha(Captcha)) {
      Toast('Please enter your captcha')
      return false;
    }
    if (!vendorType) {
      Toast('Please choose your vendor type')
      return false;
    }
    return true
  }

  const Toast = (msg, type = 'error') => {
    setopenTost(true);
    setostMessage(msg);
    setType(type)

    setTimeout(() => {
      setopenTost(false);
      setostMessage('');
      setType('')
    }, 3000);
  }

  const submitStepOne = async () => {
    let result = _validateRegisterForm();
    if (result) {
      try {
        let payload = {
          "vendor": {
            "email": Email,
            "password": Password,
            "role": vendorType, // select role 'seller','dropShipper' default is 'admin'
            "phone_number": MobileNumber,
            "first_name": Fname,
            "last_name": Lname,
          }
        }
        const response = await axios.post(`${REACT_BASE_PATH}/auth/vendor/registration`, payload);
        if (response.data.success) {
          Toast(response.data.message, 'success')
          setVendorId(response.data.vendorid)
          setIsRegister(false)
          setisOtp(true)
        }
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    }
  };

  const _handleVerifyOTP = async () => {
    if (!OTP) {
      Toast('Please enter your OTP')
      return false
    } else {
      try {
        let payload = {
          "vendor": {
            "vendor_contact": Email,
            "vendorid": VendorId,
            "otp": OTP
          }
        }
        const response = await axios.post(`${REACT_BASE_PATH}/auth/vendor/otpvarification`, payload);
        if (response.data.success) {
          Toast(response.data.message, 'success')
          // localStorage.setItem('stage_complete', 'register')
          navigate("/vendorlogin");
          // setIsKyc(true)
          // setisOtp(false)
        }
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    }
  }

  const _handleResendOTP = async () => {
    try {
      let payload = {
        "vendor": {
          "vendor_contact": Email,
          "vendorid": VendorId,
        }
      }
      const response = await axios.post(`${REACT_BASE_PATH}/auth/vendor/otpresend`, payload);
      if (response.data.success) {
        Toast(response.data.message, 'success')
        // localStorage.setItem('stage_complete', 'register')
        // setIsKyc(true)
        // setisOtp(false)
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }

  }

  const _validateKYCForm = () => {
    if(!Address){
      Toast('Please enter Your address')
      return false;
    }
    if (!CountryCode) {
      Toast('Please Select Your Country')
      return false
    }
    if (!StateCode) {
      Toast('Please Select Your State')
      return false;
    }
    if (!CityCode) {
      Toast('Please Select Your City')
      return false
    }
    if (!PinCode) {
      Toast('Please enter your pin code')
      return false
    }
    if (!IDfile) {
      Toast('Please Upload ID Proof')
      return false
    }
    if (!Addressfile) {
      Toast('Please Upload Address Proof')
      return false
    }
    if (!CaptureImage) {
      Toast('Please Capture you live picture')
      return false
    }
    return true;
  }

  const _handleKYCSubmit = async () => {
    let result = _validateKYCForm();
    if (result) {
      try {
        const formdata = new FormData();
        formdata.append('first_name', Fname);
        formdata.append('last_name', Lname);
        formdata.append('address', Address);
        formdata.append('country', CountryCode.split(":")[0]);
        formdata.append('city', CityCode.split(":")[0]);
        formdata.append('state', StateCode.split(":")[0]);
        formdata.append('postal_code', PinCode);
        formdata.append('vendor_id', VendorId);
        formdata.append('government_id', IDfile);
        formdata.append('live_photo', CaptureImage);
        formdata.append('address_proof', Addressfile);
        for (var pair of formdata.entries()) {
          console.log(pair[0] + ':- ' + pair[1]);
        }
        const vendorToken = JSON.parse(localStorage.getItem('vendor'))
        const response = await axios.post(`${REACT_BASE_PATH}/auth/vendor/vendorRegistration_Stage_kyc`, formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + vendorToken?.accessToken
            },
          });
        if (response.data.success) {
          localStorage.setItem("step_completed", response.data.updatedVendor?.signup_stage);
          if (response.data.updatedVendor?.role === 'seller') {
            setCountryCode(null)
            setStateCode(null)
            setCityCode(null)
            setIsKyc(false)
            setPinCode('')
            setIsKYB(true)
          } else {
            setIsKyc(false)
            setisSuccess(true)
          }
          Toast(response.data.message, 'success')
        }
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    }
  }

  const handleIDChange = (e) => {
    setIDFile(e[0]);
  };
  const handleAddressChange = (e) => {
    setAddressFile(e[0]);
  };
  const handleArticleChange = (e) => {
    setArticle(e[0])
  }
  const handleAddressProofChange = (e) => {
    setAddressProof(e[0])
  }
  const handleBankChange = (e) => {
    setBank(e[0])
  }

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptureImage(imageSrc);
    setIsOpen(false);
  }, [webcamRef]);

  const getCountryCode = (e) => {
    let code = e.target.value;
    setCountryCode(code)
    const countryCode = code.split(":")[1];
    const StateList = State.getStatesOfCountry(countryCode);
    setStateList(StateList);
  };

  const getStateCode = (e) => {
    let code = e.target.value;
    setStateCode(code)
    const countryCode = code.split(":")[2];
    const stateCode = code.split(":")[1];
    const cityList = City.getCitiesOfState(countryCode, stateCode);
    setCityList(cityList);
  };

  const getCityCode = (e) => {
    let code = e.target.value;
    setCityCode(code)
  };

  const _handleVendorType = (e) => {
    setvendorType(e.target.value)
  }

  const _handleAddAccountHolder = () => {
    setAddAccountHolder([
      ...addAccountHolder,
      {
        id: addAccountHolder.length + 1,
        name: "",
        mobile: "",
        email: "",
        percantage: 0,
      },
    ]);
  };

  const _handleAccountCheck = (e) => {
    setNameAsChecked(e.target.checked);
    if(e.target.checked){
      setAddAccountHolder([
        {
          id: 1,
          name: Fname+' '+Lname,
          mobile: MobileNumber,
          email: Email,
          percantage: 100,
        },
      ]);
    }else{
      setAddAccountHolder([
        {
          id: 1,
          name: '',
          mobile: '',
          email: '',
          percantage: 0,
        },
      ]);
    }
    
  };

  const _handleEye = () => {
    setisPVisible(!isPVisible);
    setpasswordType(isPVisible ? 'text' : 'password')
  }

  const _ChandleEye = () => {
    setisCVisible(!isCVisible);
    setcPasswordType(isCVisible ? 'text' : 'password')
  }

  const handleHolderChange = (index, field, value) => {
    const updateAddAccountHolder = [...addAccountHolder];
    updateAddAccountHolder[index][field] = value;
    setAddAccountHolder(updateAddAccountHolder);
  };

  const _validateKYBForm = () => {
    if (!CompanyName) {
      Toast('Please enter company name')
      return false;
    }
    if (!CompanyAddress) {
      Toast('Please enter company address')
      return false;
    }
    if (!CompanyTaxID) {
      Toast('Please enter company tax id')
      return false;
    }
    if (!CountryCode) {
      Toast('Please Select Your Country')
      return false
    }
    if (!StateCode) {
      Toast('Please Select Your State')
      return false;
    }
    if (!CityCode) {
      Toast('Please Select Your City')
      return false
    }
    if (!PinCode) {
      Toast('Please enter your pin code')
      return false
    }
    if (!Article) {
      Toast('Please upload Your article')
      return false
    }
    if (!AddressProof) {
      Toast('Please upload Your Address Proof')
      return false;
    }
    if (!Bank) {
      Toast('Please upload Your Bank Statement')
      return false
    }
    if (!PinCode) {
      Toast('Please enter your pin code')
      return false
    }
    let holderCount = 0;
    for (let index = 0; index < addAccountHolder.length; index++) {
      let holder = addAccountHolder[index];
      if (holder.percantage) {
        holderCount = holderCount + parseInt(holder.stock)
      }
    }
    if (parseInt(holderCount) > 100) {
      Toast('Please enter valid percentage')
      return false;
    }
    return true;
  }

  const _handleSubmitKYB = async () => {
    const result = _validateKYBForm();
    if (result) {
      try {
        const formdata = new FormData();
        formdata.append('company_name', CompanyName);
        formdata.append('company_ein_txid', CompanyTaxID);
        formdata.append('company_address', CompanyAddress);
        formdata.append('company_country', CountryCode.split(":")[0]);
        formdata.append('company_city', CityCode.split(":")[0]);
        formdata.append('company_state', StateCode.split(":")[0]);
        formdata.append('company_postal_code', PinCode);
        addAccountHolder.forEach((holderData, index)=>{
          if (holderData.name) {
            formdata.append(
              `beneficial_owners[${index}][name]`,
              holderData.name
            );
          }
          if (holderData.mobile) {
            formdata.append(
              `beneficial_owners[${index}][phone]`,
              holderData.mobile
            );
          }
          if (holderData.email) {
            formdata.append(
              `beneficial_owners[${index}][email]`,
              holderData.email
            );
          }
          if (holderData.percantage) {
            formdata.append(
              `beneficial_owners[${index}][percentage]`,
              holderData.percantage
            );
          }
        })
        formdata.append('vendor_id', VendorId);
        formdata.append('article_of_incorporation', Article);
        formdata.append('company_address_proof', AddressProof);
        formdata.append('bank_statement', Bank);
        for (var pair of formdata.entries()) {
          console.log(pair[0] + ':- ' + pair[1]);
        }
        const vendorToken = JSON.parse(localStorage.getItem('vendor'))
        const response = await axios.post(`${REACT_BASE_PATH}/auth/vendor/vendorRegistration_Stage_kyb`, formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + vendorToken?.accessToken
            },
          });
        if (response.data.success) {
          localStorage.setItem("step_completed", response.data.updatedVendor?.signup_stage);
          setIsKYB(false)
          setisSuccess(true)
          Toast(response.data.message, 'success')
        }
      } catch (error) {
        Toast(error.response.data.message)
      }
    }
  }


  return (
    <div className="vendorWrapper">
      <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
      <div className="vendorCard">
        <div className="vendorBox">
          <div className="vendorLogoWrapper">
            <img src={logo} alt="" />
          </div>
          {isRegister && (
            <div className="vendorForm">
              <h5 className="mb-3">Registration</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      First Name<span>*</span>
                    </label>
                    <input
                      placeholder="first name"
                      onChange={(e) => setFname(e.target.value)}
                      value={Fname}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Last Name<span>*</span>
                    </label>
                    <input
                      placeholder="last name"
                      onChange={(e) => setLname(e.target.value)}
                      value={Lname}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      E-mail<span>*</span>
                    </label>
                    <input
                      placeholder="e-mail"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={Email}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Mobile Number<span>*</span>
                    </label>
                    <input
                      placeholder="mobile number"
                      type="text"
                      onChange={(e) => setMobileNumber(e.target.value)}
                      value={MobileNumber}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Password<span>*</span>
                    </label>
                    <input
                      placeholder="**********"
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                      value={Password}
                      type={passwordType}
                    />
                    {isPVisible ? <span className="eyeset" style={{ cursor: 'pointer' }} onClick={_handleEye}><VisibilityIcon sx={{ fontSize: 16 }} /></span>
                      : <span className="eyeset" style={{ cursor: 'pointer' }} onClick={_handleEye}><VisibilityOffIcon sx={{ fontSize: 16 }} /></span>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Confirm Password<span>*</span>
                    </label>
                    <input
                      placeholder="**********"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={ConfirmPassword}
                      className="form-control"
                      type={cPasswordType}
                    />
                    {isCVisible ? <span className="eyeset" style={{ cursor: 'pointer' }} onClick={_ChandleEye}><VisibilityIcon sx={{ fontSize: 16 }} /></span>
                      : <span className="eyeset" style={{ cursor: 'pointer' }} onClick={_ChandleEye}><VisibilityOffIcon sx={{ fontSize: 16 }} /></span>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 flexCaptcha">
                  <LoadCanvasTemplate reloadColor="blue" reloadText="Reload" />
                  <input
                    placeholder="Enter Captcha Value"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                    onChange={(e) => setCaptcha(e.target.value)}
                    value={Captcha}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <div>
                    <label className="lableText">
                      <input
                        placeholder="Enter Captcha Value"
                        name="userType"
                        type="radio"
                        value={'seller'}
                        onChange={_handleVendorType}
                      />
                      I'm a Seller, who wants to sell his own product
                    </label>
                  </div>
                  <div>
                    <label className="lableText">
                      <input
                        placeholder="Enter Captcha Value"
                        name="userType"
                        type="radio"
                        value={'dropShipper'}
                        onChange={_handleVendorType}
                      />
                      I'm a Seller, who need DropShipping Product and Services
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button className="submitFormBtn" onClick={submitStepOne}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
          {isOtp && (
            <div className="vendorForm">
              <h5 className="mb-3">verify Your OTP</h5>
              <p>We've sent an OTP on your register Email Id</p>
              <div className="row">
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      OTP<span>*</span>
                    </label>
                    <input
                      placeholder="enter otp"
                      onChange={(e) => setOTP(e.target.value)}
                      value={OTP}
                      className="form-control"
                    />
                    <label className="lableText" style={{ color: '#d74d74', marginTop: 10, cursor: 'pointer' }} onClick={_handleResendOTP}>
                      Resend OTP
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button className="submitFormBtn" onClick={_handleVerifyOTP}>Verify OTP</button>
                </div>
              </div>
            </div>
          )}
          {isKyc && (
            <div className="vendorForm">
              <h5 className="mb-3">Complete Your KYC</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      First Name<span>*</span>
                    </label>
                    <input placeholder="first name" className="form-control" value={Fname} disabled />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Last Name<span>*</span>
                    </label>
                    <input placeholder="last name" className="form-control" value={Lname} disabled />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      E-mail<span>*</span>
                    </label>
                    <input
                      placeholder="e-mail"
                      type="email"
                      value={Email} disabled
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Mobile Number<span>*</span>
                    </label>
                    <input
                      placeholder="mobile number"
                      type="text"
                      className="form-control"
                      value={MobileNumber} disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="vendorInput">
                    <label className="lableText">
                      Address<span>*</span>
                    </label>
                    <textarea
                      placeholder="address"
                      type="email"
                      className="form-control"
                      value={Address}
                      onChange={(e) => setAddress(e.target.value)}
                      rows={2}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Country<span>*</span>
                    </label>
                    <select className="form-control" onChange={getCountryCode}>
                      <option value={''}>Select Country</option>
                      {CountryList.length &&
                        CountryList.map((item, index) => {
                          return (
                            <option value={item?.name + ":" + item.isoCode}>
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      State<span>*</span>
                    </label>
                    <select className="form-control" onChange={getStateCode}>
                      <option value={''}>Select State</option>
                      {StateList.length &&
                        StateList.map((item) => {
                          return (
                            <option
                              value={
                                item?.name +
                                ":" +
                                item.isoCode +
                                ":" +
                                item?.countryCode
                              }
                            >
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      City<span>*</span>
                    </label>

                    <select className="form-control" onChange={getCityCode}>
                      <option value={''}>Select City</option>
                      {CityList.length &&
                        CityList.map((item) => {
                          return (
                            <option
                              value={
                                item?.name +
                                ":" +
                                item.stateCode +
                                ":" +
                                item?.countryCode
                              }
                            >
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Pin Code<span>*</span>
                    </label>
                    <input placeholder="pin code" className="form-control" onChange={(e) => setPinCode(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="lableText">
                    Upload Your ID<span>*</span>
                  </label>
                  <FileUploader
                    multiple={true}
                    handleChange={handleIDChange}
                    name="file"
                    types={fileTypes}
                  />
                </div>
                <div className="col-md-6">
                  <label className="lableText">
                    Upload Address Proof<span>*</span>
                  </label>
                  <FileUploader
                    multiple={true}
                    handleChange={handleAddressChange}
                    name="file"
                    types={fileTypes}
                  />
                </div>
                <div className="col-md-6">
                  <label className="lableText mt-3">
                    Upload Live Pic<span>*</span>
                  </label>
                  {CaptureImage && (
                    <img src={CaptureImage} alt="" className="captureImage" />
                  )}
                  {CaptureImage ? (
                    <button
                      className="btnBordered"
                      onClick={() => {
                        setCaptureImage(null);
                        setIsOpen(true);
                      }}
                    >
                      Retake Photo
                    </button>
                  ) : (
                    <button
                      className="btnBordered"
                      onClick={() => setIsOpen(true)}
                    >
                      Click Photo
                    </button>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button className="submitFormBtn" onClick={_handleKYCSubmit}>Submit</button>
                </div>
              </div>
            </div>
          )}
          {isKYB && (
            <div className="vendorForm">
              <h5 className="mb-3">Complete Your KYB</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Company Name<span>*</span>
                    </label>
                    <input
                      placeholder="company name"
                      className="form-control"
                      value={CompanyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Company TaxID<span>*</span>
                    </label>
                    <input
                      placeholder="company tax id"
                      className="form-control"
                      value={CompanyTaxID}
                      onChange={(e) => setCompanyTaxID(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="vendorInput">
                    <label className="lableText">
                      Company Address<span>*</span>
                    </label>
                    <textarea
                      placeholder="company address"
                      type="email"
                      className="form-control"
                      value={CompanyAddress}
                      onChange={(e) => setCompanyAddress(e.target.value)}
                      rows={3}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Country<span>*</span>
                    </label>
                    <select className="form-control" onChange={getCountryCode}>
                    <option value={''}>Select Country</option>
                      {CountryList.length &&
                        CountryList.map((item) => {
                          return (
                            <option value={item?.name + ":" + item.isoCode}>
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      State<span>*</span>
                    </label>
                    <select className="form-control" onChange={getStateCode}>
                    <option value={''}>Select State</option>
                      {StateList.length &&
                        StateList.map((item) => {
                          return (
                            <option
                              value={
                                item?.name +
                                ":" +
                                item.isoCode +
                                ":" +
                                item?.countryCode
                              }
                            >
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      City<span>*</span>
                    </label>

                    <select className="form-control" onChange={getCityCode}>
                    <option value={''}>Select City</option>
                      {CityList.length &&
                        CityList.map((item) => {
                          return (
                            <option
                              value={
                                item?.name +
                                ":" +
                                item.stateCode +
                                ":" +
                                item?.countryCode
                              }
                            >
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vendorInput">
                    <label className="lableText">
                      Pin Code<span>*</span>
                    </label>
                    <input placeholder="pin code" className="form-control" value={PinCode}
                      onChange={(e) => setPinCode(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="lableText">
                    Upload Articles<span>*</span>
                  </label>
                  <FileUploader
                    multiple={true}
                    handleChange={handleArticleChange}
                    name="file"
                    types={fileTypesPDF}
                  />
                </div>
                <div className="col-md-6">
                  <label className="lableText">
                    Upload Address Proof<span>*</span>
                  </label>
                  <FileUploader
                    multiple={true}
                    handleChange={handleAddressProofChange}
                    name="file"
                    types={fileTypes}
                  />
                </div>
                <div className="col-md-6">
                  <label className="lableText">
                    Upload Bank Statement<span>*</span>
                  </label>
                  <FileUploader
                    multiple={true}
                    handleChange={handleBankChange}
                    name="file"
                    types={fileTypesPDF}
                  />
                </div>
              </div>
              <div>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <label className="lableText">
                      <input type="checkbox" onChange={_handleAccountCheck} />
                      Name is same as Account Holder
                    </label>
                  </div>
                  <div className="text-end col-md-6">
                    {!NameAsChecked && (
                      <button
                        className="plusBtn"
                        onClick={_handleAddAccountHolder}
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {addAccountHolder.length &&
                addAccountHolder.map((item, index) => (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="vendorInput">
                        <label className="lableText">
                          Name<span>*</span>
                        </label>
                        <input placeholder="name" className="form-control" value={item.name} onChange={(e) => {
                          handleHolderChange(index, 'name', e.target.value)
                        }} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="vendorInput">
                        <label className="lableText">
                          Mobile Number<span>*</span>
                        </label>
                        <input
                          placeholder="mobile number"
                          className="form-control"
                          value={item.mobile} onChange={(e) => {
                            handleHolderChange(index, 'mobile', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="vendorInput">
                        <label className="lableText">
                          Email<span>*</span>
                        </label>
                        <input placeholder="email" className="form-control" value={item.email} onChange={(e) => {
                          handleHolderChange(index, 'email', e.target.value)
                        }} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="vendorInput">
                        <label className="lableText">
                          Percantage of Ownership<span>*</span>
                        </label>
                        <input
                          placeholder="ownership percantage"
                          className="form-control"
                          value={item.percantage} onChange={(e) => {
                            handleHolderChange(index, 'percantage', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              <div className="row">
                <div className="col-md-12 text-center">
                  <button className="submitFormBtn" onClick={_handleSubmitKYB}>Submit for Approval</button>
                </div>
              </div>
            </div>
          )}
          {isSuccess && (
            <div className="text-center">
              <img src={check} alt="check" className="mb-3" />
              <h5 className="mb-3">Thank you for your submission.</h5>
              <p>Kindly allow 24 hours for verification.</p>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
      >
        {!CaptureImage && (
          <>
            <Webcam
              audio={false}
              height={500}
              ref={webcamRef}
              mirrored={true}
              screenshotFormat="image/jpeg"
              width={500}
              videoConstraints={videoConstraints}
            />
            <div className="m-3 text-center">
              <button className="btnBordered" onClick={capture}>
                Capture photo
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default VendorRegistration;
