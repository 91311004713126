import React, { useEffect, useState } from 'react'
import Header from "../AdminPanel/component/Header";
import { Route, Routes, useNavigate } from 'react-router-dom';
import Sidebar from '../AirdropDashboardPages/Sidebar';
import Products from '../AffilateDashboardPages/Products';
import Ladger from '../AdminPanel/component/affilateDashboard/Ladger';
import AllTask from '../AirdropDashboardPages/AllTask';
import AllHistory from '../AirdropDashboardPages/AllHistory';
import axios from 'axios';
import { getAffilateAccessToken, getAffilateUserId } from '../../utills';
import { _getProfile } from '../../url';
import { isJwtExpired } from 'jwt-check-expiration';
import toast from 'react-hot-toast';
import Dashboard from '../AffilateDashboardPages/Dashboard';
// import '../../components/AdminPanel/AdminPanelRoutes.css'


function AirDropRoutes() {
  useEffect(() => {
    import('../../components/AdminPanel/AdminPanelRoutes.css');
  }, [])
    const [collapsed, setCollapsed] = useState(false);
    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };
    const [userInfo, setuserInfor] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      if(isJwtExpired(getAffilateAccessToken())) {
        toast.error("Token Has Expired,you need to login again");
        setTimeout(() => {
          navigate('/affiliate_login');  
        },[])
      }
    },[navigate])



    useEffect(() => {
        const fetchUerInfo = async () => {
            try {
                const response = await axios({
                  url : _getProfile,
                  method : 'post',
                  headers : {
                    authorization: `Bearer ${getAffilateAccessToken()}`,
                  },
                  data : {
                     id : getAffilateUserId()
                  }
                });
                setuserInfor(response.data.data); // Adjust based on your API response structure
                console.log('fetchUerInfo labels:', response.data.data);
            } catch (error) {
                console.error('Error fetching labels:', error);
                // toast.error('Failed to fetch labels.');
            }
        };
        fetchUerInfo();
    }, []);
    
  return (
    <div className="app admin-panel-body">
    <Header collapsed={collapsed} onToggle={toggleSidebar} />
    <div className="main-container">
        <Sidebar
            collapsed={collapsed}
        />
        <div className="main-content">
            <Routes>
              <Route path="/dashboard" element={<Dashboard role="airdrop"/>} />
              <Route path="/all_task" element={<AllTask userInfo={userInfo}/>} />
              <Route path="/all_history" element={<AllHistory />} />
              {/* <Route path="*" element={<NotFound />} />  */}
            </Routes>
        </div>
    </div>
</div>
  )
}

export default AirDropRoutes