import React from "react";
import { NavLink } from "react-router-dom";

function Sidebar({ collapsed }) {
  const sideLinks = [
    {
      path : "/affiliate/dashboard",
      title : 'Dashboard',
      icon : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-layout-dashboard"><rect width="7" height="9" x="3" y="3" rx="1"/><rect width="7" height="5" x="14" y="3" rx="1"/><rect width="7" height="9" x="14" y="12" rx="1"/><rect width="7" height="5" x="3" y="16" rx="1"/></svg>
    },
    {
      path: "/affiliate/products",
      title: "Products",
      icon: <i class="fa fa-cart-shopping" aria-hidden="true"></i>,
    },
    {
      path: "/affiliate/history",
      title: "History",
      icon: <i class="fa fa-history" aria-hidden="true"></i>,
    },
    {
      path: "/affiliate/wallet",
      title: "wallet",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wallet"><path d="M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1"/><path d="M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4"/></svg>,
    },
    {
      path: "/airdrop/all_task",
      title: "Airdrop",
      icon: <i class="fa fa-user" aria-hidden="true"></i>,
    },
  ];
  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      {!collapsed && (
        <>
          {sideLinks?.map(({ path, title, icon }) => (
            <div className="nav-section d-flex justify-content-start align-items-center py-1">
              <NavLink
                to={path}
                style={{ color: "#fff", textDecoration: "none" }}
                className={({ isActive, isPending }) => isActive ? "activeClass sidebarList" : "sidebarList"}
              >
                {icon}
                {"  "}
                <span className="slidebarspan">{title}</span>
              </NavLink>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default Sidebar;
