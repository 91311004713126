import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ApiCall } from "../../services";
import {
  getAccessTokenURL,
  getSettingDataURL,
  updateProductByCategoryURL,
} from "../../../../url";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CircularProgress } from "@mui/material";
import { Settings } from "@mui/icons-material";

export default function UpdateSetting() {
  const [codeVal, setcode] = React.useState("");
  const [credentials, setcredentials] = React.useState(null);
  const [myaccess_token, setMyaccess_token] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [Settings, setSetting] = React.useState({
    app_key: null,
    app_secret: null,
    access_token: null,
  });

  //   React.useEffect

  React.useEffect(() => {
    async function fetchCredentails() {
      try {
        let res = await axios.get(getSettingDataURL);
        const result = res?.data;
        if (result?.success) {
          const { app_key, redirect_url, appSecret, code, access_token } =
            result.data;
          setcredentials({
            app_key: app_key,
            redirect_url: redirect_url,
            appSecret: appSecret,
            code: code,
            access_token: access_token,
          });

          setSetting({
            app_key: app_key,
            app_secret: appSecret,
            access_token: access_token,
          });
        }
      } catch (error) {
        console.error("error", error);
      }
    }
    fetchCredentails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    ApiCall(updateProductByCategoryURL, {
      ...credentials,
      code: codeVal,
    })
      .then((res) => {
        if (res.data.success) {
          return true;
        }
        return false;
      })
      .then((res) => {
        setLoading(true);
        return ApiCall(getAccessTokenURL, {}, "get");
      })
      .then((res) => {
        const data = res.data;
        console.log("ldjfdfd", data);
        const access_token = data.access_token;
        if (!access_token) {
          toast.error(`Token Already Genereted ${data.code}`);
          setLoading(false);
        } else {
          toast.success(`Access token generated successfully`);
          setMyaccess_token(access_token);
          setLoading(false);
        }
        setcode("");
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });

    console.log(",,", {
      ...credentials,
      code: codeVal,
    });
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setSetting((prev) => {
      return {
        ...prev,
        [e.target.name]: val,
      };
    });
  };

  const handleSubmitSetting = (e) => {
    e.preventDefault();
     console.log("setting data", Settings);
     setLoading(true);
      ApiCall(updateProductByCategoryURL, {
        app_key : Settings.app_key,
        app_secret : Settings?.app_secret,
        access_token : Settings?.access_token,
        code : credentials?.code,
      }).then(res => {
         toast.success('Setting updated Successfully');
         window.location.reload();
         setLoading(false);
      }).catch(res => {
         toast.error('Somthing went wrong');
         setLoading(false);
      })
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.children}
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {index === steps.length - 1 ? "Finish" : "Continue"}
                </Button>
                <Button
                  disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper> */}
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
      {/* <Button variant="text" size="large">
        <a href="https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://krizaar.com/&client_id=508770">
          Click to generate code
        </a>
      </Button> */}

        <div className="py-4 mt-4">
          <div
            className="p-2 bg-light d-flex justify-content-between"
            style={{ background: "lightgray" }}
          >
             <div>  current code: <h5>{credentials?.code}</h5></div>
            <Button variant="text" size="large">
        <a href="https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://krizaar.com/&client_id=508770">
          Click to generate New Code
        </a>
      </Button>

          </div>
        </div>

      <form
        className="d-flex align-items-center gap-1 mt-2"
        onSubmit={handleSubmit}
      >
        <div className="w-75">
          {/* <p className="p-0">Enter generated code to get access token</p> */}
          <input
            value={codeVal}
            onChange={(e) => setcode(e.target.value)}
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter generated code here and update..."
            required
          />
        </div>

        {loading && <CircularProgress />}
        {!loading && (
          <Button type="submit" size="medium" variant="contained" className="">
            Update Token
          </Button>
        )}
      </form>

      {myaccess_token && (
        <div className="py-4 mt-4">
          <h4 className="text-success">Access Token generated successfully</h4>
          <div
            className="p-2 bg-light d-flex justify-content-between"
            style={{ background: "lightgray" }}
          >
            <h5>{myaccess_token}</h5>
            <CopyToClipboard
              text={myaccess_token}
              onCopy={() => toast.success("Token Copied")}
            >
              <Button variant="text">
                <ContentCopyIcon />
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      )}

      <form
        className="d-flex flex-column align-items-start gap-1 mt-2"
        onSubmit={handleSubmitSetting}
      >
        <h3 className="my-2">Update Settings</h3>
        <div className="w-75">
          <lable className="my-2 text-dark">App Key</lable>
          <input
            value={Settings.app_key}
            onChange={handleChange}
            type="text"
            name="app_key"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder=""
            required
          />
        </div>
        <div className="w-75">
          <lable className="my-2 text-dark">App Secret</lable>
          <input
            value={Settings.app_secret}
            onChange={handleChange}
            type="text"
            name="app_secret"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder=""
            required
          />
        </div>
        <div className="w-75">
          <lable className="my-2 text-dark">Access Token</lable>
          <input
            value={Settings.access_token}
            onChange={handleChange}
            type="text"
            name="access_token"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder=""
            required
          />
        </div>

        {loading && <CircularProgress />}
        {!loading && (
          <Button
            type="submit"
            size="medium"
            variant="contained"
            className="mt-4"
          >
            Update Setting
          </Button>
        )}
      </form>

      {/* <Toaster /> */}
    </Box>
  );
}
