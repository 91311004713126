import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../header/Header";
import Footer from "../header/Footer";
import { REACT_BASE_PATH } from "../../api";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";


// import { LocalWallet, SmartWallet } from '@thirdweb-dev/wallets';
// import { ThirdwebSDK } from '@thirdweb-dev/sdk';
// import { Optimism } from '@thirdweb-dev/chains';
// import ThirdwebConnectButton from "./ThirdwebConnectButton";

// const factoryAddress = process.env.REACT_APP_FACTORY_ADDRESS;
// const clientId = process.env.REACT_APP_THIRDWEB_CLIENT_ID;

const Signup = () => {
  const navigate = useNavigate();
  const [showFirst, setShowFirst] = useState(true);
  const [showSecond, setShowSecond] = useState(false);
  const [showThird, setShowThird] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // const [personalWalletAddress, setPersonalWalletAddress] = useState(null);
  // const [smartWalletAddress, setSmartWalletAddress] = useState(null);
  // const [transactionHash, setTransactionHash] = useState(null);

  useEffect(() => {
    const userToken = Cookies.get('krizaar-user');
    const userId = localStorage.getItem('userId');
    if (userToken && userId) {
      navigate("/");
      return;
    }
  }, [])

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");

  const [user, setUser] = useState({});
  // console.log(user, "-----------------------------------------------------");

  const submitData = () => {
    if (email && password) {
      axios
        .post(`${REACT_BASE_PATH}/auth/user/registration`, {
          user: {
            user_contact: email,
            password: password,
          },
        })
        .then((resp) => {
          setUser(resp.data);
          setShowFirst(false);
          setShowSecond(false);
          setShowThird(true);
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || "Some error occured, please try again");
          if (err.response?.status === 422) {
            if (err.response?.data?.message === "This user email alredy registered") {
              navigate('/signin')
            }
          }
          // console.log("error", err.response?.data?.message);
        });
    }
  };

  const verifyEmail = () => {
    if (otp) {
      console.log("Verifying OTP");
      console.log(user)
      axios
        .post(`${REACT_BASE_PATH}/auth/user/otpvarification`, {
          user: {
            user_contact: user.mail,
            userId: user.userid,
            otp: otp,
          },
        })
        .then((resp) => {
          console.log(resp, "OTP Verification Response");

          // Automatically log in the user after successful OTP verification
          loginUser(email, password);
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || "Some server error occured")
          // console.log("Error during OTP verification", err);
        });
    }
  };

  const handleResendOTP = () => {
    axios
      .post(`${REACT_BASE_PATH}/auth/user/otpresend`, {
        user: {
          user_contact: user.mail,
          userId: user.userid,
        },
      })
      .then((resp) => {
        toast.success('OTP has been resent successfully')
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Some server error occured");
        // console.log("Error during OTP resend", err);
      });
  }

  const loginUser = (email, password) => {
    axios
      .post(`${REACT_BASE_PATH}/auth/user/login`, {
        user: {
          user_contact: email,
          password: password,
        },
      })
      .then((response) => {
        // console.log(response.data, "Login Successfully");

        Cookies.set('krizaar-user', response.data.accessToken);

        localStorage.setItem("userId", response.data.userid);
        navigate("/userprofile");
      })
      .catch((error) => {
        console.log(error.response?.data?.message || "An error occurred");
      });
  };

  const handleGoogleLogin = async (response) => {
    const { credential } = response;
    const { email, given_name, picture } = jwtDecode(credential);
    // console.log(response)
    // setEmail(email)

    try {
      const result = await axios.post(`${REACT_BASE_PATH}/auth/user/google/login`, {
        email: email,
        first_name: given_name,
        password: "12345678",
        token: credential,
        // picture
      })
      // console.log(result)
      if (result.status === 200) {
        Cookies.set('krizaar-user', result.data.accessToken, { expires: 1 / 3 });
        localStorage.setItem("userId", result.data.userid);
        // handleWalletFlow();
        navigate('/userprofile')
      }
    } catch (e) {
      if (e.response?.status === 422) {
        toast.warning('You are using a different method. Please use the method for login, which you have used while registering.')
      }
      toast.error(e.response?.data?.message || "Some Error occured")
    }
  }
  const handleError = async (error) => {
    toast.error("Some Error occured")
    // console.log(error)
  }

  // Function to connect the personal wallet
  // const connectPersonalWallet = async () => {
  //   try {
  //     const personalWallet = new LocalWallet();
  //     await personalWallet.generate(); // Generate a new local wallet
  //     const address = await personalWallet.getAddress();
  //     setPersonalWalletAddress(address);
  //     console.log("Personal Wallet Address:", address);
  //     return personalWallet;
  //   } catch (error) {
  //     console.error("Error connecting personal wallet:", error);
  //   }
  // };

  // Function to connect the smart wallet
  // const connectSmartWallet = async (personalWallet) => {
  //   try {
  //     const config = {
  //       chain: Optimism, // Set the chain to Optimism
  //       factoryAddress: factoryAddress, // Use the environment variable for the factory address
  //       clientId: clientId, // Use the environment variable for the Thirdweb client ID
  //       gasless: true, // Enable gasless transactions if needed
  //     };

  //     const smartWallet = new SmartWallet(config);
  //     await smartWallet.connect({ personalWallet });
  //     const address = await smartWallet.getAddress();
  //     setSmartWalletAddress(address);
  //     console.log("Smart Wallet Address:", address);
  //     return smartWallet;
  //   } catch (error) {
  //     console.error("Error connecting smart wallet:", error);
  //   }
  // };

  // Function to handle the wallet flow
  // const handleWalletFlow = async () => {
  //   const personalWallet = await connectPersonalWallet();
  //   const smartWallet = await connectSmartWallet(personalWallet);
  // };

  return (
    <>
      <section>
        <div className="container my-5">
          {showFirst && (
            <div className="row">
              <div className="signin-box">
                <div className="signin-box1">
                  <h4 className="text-center mt-4">Krizaar.com</h4>
                  <div className="sign-form">
                    <h5 className="mb-4">Sign Up</h5>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="email-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <br />
                    <br />
                    <div className="sbtn-box">
                      <button
                        onClick={() => {
                          if (!email) {
                            toast.warning('Please enter your email')
                            return
                          }
                          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          if (!emailRegex.test(email)) {
                            toast.warning('Please enter a valid email');
                            return;
                          }
                          setShowFirst(false);
                          setShowSecond(true);
                        }}
                        className="sub-btn"
                      >
                        Continue
                      </button>
                    </div>
                    <div className="or-div text-center my-4">
                      or
                    </div>
                    <div className="sbtn-box mb-3">
                      <GoogleLogin
                        onSuccess={handleGoogleLogin}
                        onError={handleError}
                      />
                    </div>
                    <p className="mt-4">
                      By continuing, you agree to Krizaar's
                      <NavLink className='text-primary' to='/terms-of-service'> Terms of Service </NavLink>
                      and
                      <NavLink className='text-primary' to='/privacy-policy'> Privacy Policy</NavLink>
                    </p>
                  </div>
                </div>
              </div>
              <hr className="mt-4" />
              <div className="amazon-box">
                <span className="amazon">New to Krizaar?</span>
              </div>
              <div className="create-acc-box">
                <NavLink to="/signin">
                  <button className="create-acc">
                    Login to your Krizaar account
                  </button>
                </NavLink>
              </div>
            </div>
          )}

          {showSecond && (
            <div className="row">
              <div className="signin-box">
                <div className="signin-box1">
                  <h4 className="text-center mt-5">Krizaar.com</h4>
                  <div className="sign-form">
                    <h5 className="mb-4">Set Password</h5>
                    <label htmlFor="password">Password</label>
                    <div className="password-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className=""
                        placeholder=""
                        required
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <br />
                    <br />
                    <div className="sbtn-box">
                      <button
                        onClick={() => {
                          // setShowSecond(false);
                          // setShowThird(true);
                          if (!password) {
                            toast.warning('Please enter your password')
                            return
                          }
                          submitData();
                        }}
                        className="sub-btn"
                      >
                        Submit
                      </button>
                    </div>
                    <label htmlFor="agree" className="mt-4">
                      <input type="checkbox" id="agree" name="agree" /> I agree
                      to the terms and conditions
                    </label>
                  </div>
                </div>
              </div>
              <hr className="mt-4" />
              <div className="amazon-box">
                <span className="amazon">or</span>
              </div>
              <div className="create-acc-box">
                <NavLink to="/signin">
                  <button className="create-acc">
                    Login to your Krizaar account
                  </button>
                </NavLink>
              </div>
            </div>
          )}

          {showThird && (
            <div className="row">
              <div className="signin-box">
                <div className="signin-box1">
                  <h4 className="text-center mt-4">Krizaar.com</h4>
                  <div className="sign-form">
                    <h5 className="mb-4">Authentication Required</h5>
                    <p>
                      We’ve sent a One Time Password (OTP) to the mobile
                      number/Email above. Please enter it to complete
                      verification.
                    </p>
                    <label htmlFor="otp">Enter OTP</label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className="email-input"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                    <br />
                    <br />
                    <div className="sbtn-box">
                      <button onClick={verifyEmail} className="sub-btn">
                        Verify
                      </button>
                    </div>
                    <div className="resend-otp">
                      <button onClick={handleResendOTP}>Resend OTP</button>
                    </div>
                    {/* <div className="sbtn-box">
                      <ThirdwebConnectButton />
                    </div> */}
                    {/* <div className="wallet-connection mt-4">
                      <button onClick={handleWalletFlow} className="sub-btn">
                        Connect Smart Wallet
                      </button>
                      {personalWalletAddress && (
                        <p>Personal Wallet Address: {personalWalletAddress}</p>
                      )}
                      {smartWalletAddress && (
                        <p>Smart Wallet Address: {smartWalletAddress}</p>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <hr className="mt-4" />
              <div className="amazon-box">
                <span className="amazon">or</span>
              </div>
              <div className="create-acc-box">
                <NavLink to="/signin">
                  <button className="create-acc">
                    Login to your Krizaar account
                  </button>
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Signup;
