import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from "./componenth/common/AuthContext";
import ProtectedRoute from "./componenth/common/ProtectedRoute";
import AdminPanelRoutes from './components/AdminPanelRoutes/AdminPanelRoutes';
import WebPanelRoutes from './components/WebPanelRoutes/WebPanelRoutes';
import AffilateDashboardRoutes from './components/AffilateDashboard/AffilateDashboardRoutes';
import AirDropRoutes from './components/AirdropDashboard/Routes';

const App = () => {
  const vendor = JSON?.parse(localStorage?.getItem('vendor')) || '';
  const { role } = vendor;
  return (
  <AuthProvider>
    <Router>
      <Routes>
        {role === 'admin' ?
        <Route path="/admin/*" element={<ProtectedRoute><AdminPanelRoutes /></ProtectedRoute>} />
        :<Route path="/vendor/*" element={<ProtectedRoute><AdminPanelRoutes /></ProtectedRoute>} />}
        <Route path="/*" element={<WebPanelRoutes />} />
        <Route path="/affiliate/*" element={<AffilateDashboardRoutes />} />
        <Route path="/airdrop/*" element={<AirDropRoutes />} />
      </Routes>
    </Router>
  </AuthProvider>
  )
};

export default App;

