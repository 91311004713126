import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const SanctionsPolicy = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className='terms-of-service my-5'>
            <h1 className="my-3">Krizaar Sanctions Policy</h1>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div style={{ textAlign: 'justify' }}>
                <p>Krizaar connects buyers and sellers worldwide. When using Krizaar’s services, you must adhere to this Sanctions Policy, no matter your location. This policy is part of Krizaar's Terms of Use, meaning that by using the platform, you agree to both.</p>
                <p>As a U.S.-based company, Krizaar follows international sanctions and trade restrictions, such as those enforced by the U.S. Department of the Treasury’s Office of Foreign Assets Control (OFAC). This means you cannot engage in transactions involving certain people, places, or goods restricted by these regulations.</p>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Restrictions</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>Sanctions typically prohibit transactions involving:
                      <ol>
                        <li>1.	Specific regions, including Crimea, Cuba, Iran, North Korea, Syria, Russia, Belarus, and the Donetsk and Luhansk regions of Ukraine.</li>
                        <li>2.	Individuals or entities listed on sanctions lists like OFAC’s Specially Designated Nationals (SDN) List or Foreign Sanctions Evaders (FSE) List.</li>
                        <li>3.	Cuban nationals (regardless of location) unless they have citizenship or permanent residency outside of Cuba.</li>
                        <li>4.	Items from areas like Cuba, North Korea, Iran, or Crimea, except informational materials like publications or certain types of art.</li>
                        <li>5.	Goods, services, or technology from DNR and LNR, excluding informational materials and specific agricultural products.</li>
                        <li>6.	Products of Russian origin, including fish, seafood, diamonds, and gold, restricted for import into the U.S.</li>
                        <li>7.	Exporting luxury goods from the U.S. to individuals in Russia or Belarus, based on regulations set by the U.S. Secretary of Commerce.</li>
                        <li>8.	Items produced with forced labor, prohibited by U.S. trade laws.</li>
                      </ol>
                      <p>Krizaar enforces compliance by prohibiting users from accessing accounts in sanctioned areas. If you are found to be using your account from a restricted location, Krizaar may suspend or terminate your account. Similarly, members are generally not allowed to buy, sell, or ship items to or from sanctioned regions, even if those items predate sanctions. Sellers may also be required to provide information about the origin of their products to ensure compliance.</p>
                      <p>In addition to U.S. laws, users should be aware of trade restrictions in other countries. Transactions involving international parties must comply with relevant local and international laws. Third-party payment processors, like PayPal, may independently block transactions for sanctions compliance, and Krizaar does not control their decisions.</p>
                      <p>Since sanctions and restrictions can change, users are advised to stay informed about current regulations and seek legal advice when needed.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SanctionsPolicy;
