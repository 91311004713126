import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const SelectTokenModal = ({ isOpen, onClose, onSelectCurrency }) => {
  if (!isOpen) return null;

  const tokens = [
    { label: 'ADA', name: 'Cardano', image: '/images/ada.png' },
    { label: 'BNB', name: 'Binance Coin', image: '/images/bnb.png' },
    { label: 'ETH', name: 'Ethereum', image: '/images/eth.png' },
    { label: 'DASH', name: 'Dash', image: '/images/dash.png' },
    { label: 'DAI', name: 'Dai Stablecoin', image: '/images/dai.png' },
    { label: 'ETC', name: 'Ethereum Classic', image: '/images/etc.png' },
    { label: 'NEXO', name: 'Nexo', image: '/images/nexo.png' },
    { label: 'UNI', name: 'Uniswap', image: '/images/uni.png' },
  ];

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className='text-start'>
            <h3>Select a Token</h3>
            <p>Select a token from our default list or search for a token by symbo or address</p>
          </div>
          <button onClick={onClose}>X</button>
        </div>
        <div className="modal-body">
          <input type="text" placeholder="Search by token or address" />
          <div className="token-list">
            {tokens.map((token) => (
              <div
                key={token.label}
                className="token-item"
                onClick={() => onSelectCurrency(token.label)}
              >
                <div className='token-details'>
                  <img src={token.image} alt={token.label} />
                  <div className='token-name'>
                    <h6 className='m-0'>{token.label}</h6>
                    <p className='m-0'>{token.name}</p>
                  </div>
                </div>
                <span className='right-arrow'><FaArrowRight /></span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTokenModal;
