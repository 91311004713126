import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { REACT_BASE_PATH } from "../../../api";
import SnackBar from '../helper/SnackBar';
import { addAttributeMessage, deleteAttributeMessage } from "./ResponseMessage";

const AllAttributes = () => {
  useEffect(() => {
    import('../AdminPanelRoutes.css');
  }, [])
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const tableRef = useRef(null);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [uniterror, setuniterror] = useState(false);
  const [categoryValue, setcategoryValue] = useState([]);
  const [categoryId, setcategoryId] = useState('');
  const [openTost, setopenTost] = useState(false);
  const [tostMessage, setostMessage] = useState('');
  const [type, setType] = useState('');
  const [editValue, seteditValue] = useState('');
  const [attributeType, setAttributeType] = useState('Add')
  const [selectedUnit, setselectedUnit] = useState('')
  const vendor = JSON.parse(localStorage.getItem('vendor'))
  const { role } = vendor;

  const handleNameChange = (event) => {
    if (event.target.value) {
      setNameError(false);
    }
    setName(event.target.value);
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);
    setCurrentPage(1);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${REACT_BASE_PATH}/admin/attribute/getAllAttributes`,
      );
      if (response.data.status == 200) {
        setData(response?.data?.data);
      }
      setTotalItems(2);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, selectedOption]);

  const fetchCategory = async () => {
    let payload = {
      parent_id: 0
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/productcategory`, payload);
      if (response.data.status === 200) {
        setcategoryId((editValue?.category_id) ? editValue?.category_id?._id : response.data.data[0]._id)
        setcategoryValue(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  const addattribute = async (catId) => {
    let payload = {
      categoryId: catId,
      attributeName: name,
      units: selectedUnit
    }
    if (attributeType == 'Edit') {
      payload = { ...payload, attributeId: editValue._id }
    }

    try {
      let path = attributeType == 'Add' ? '/admin/attribute/addAttribute' : '/admin/attribute/updateAttribute';
      const response = await axios.post(`${REACT_BASE_PATH}` + path, payload);
      if (response.data.status === 200) {
        setopenTost(true);
        setostMessage(addAttributeMessage);
        setType('success');
        fetchData()
      }
    } catch (error) {
      setopenTost(true);
      setostMessage(error.message);
      setType('error');
      console.error("Error fetching category:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const totalPages = Math.ceil(totalItems / selectedOption);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const addAttribute = () => {
    if (!name) {
      setNameError(true);
      return;
    } else if (!selectedUnit) {
      setuniterror(true);
      return;
    }
    addattribute(categoryId);
  };

  const openSideBar = (type) => {
    if (type === 'add') {
      setName('');
      seteditValue('');
      setselectedUnit('')
      setAttributeType('Add');
    }
    fetchCategory();
  }

  const categoryChange = (event) => {
    setcategoryId(event.target.value);
  };

  const onEditAttribute = (item) => {
    seteditValue(item);
    setName(item.attribute_name)
    setselectedUnit(item.units)
    setAttributeType('Edit')
    openSideBar('edit');
  }

  const deleteAttribute = async (id) => {
    try {
      let payload = {
        attributeId: id,
      }
      const response = await axios.post(`${REACT_BASE_PATH}/admin/attribute/deleteAttribute`, payload);
      if (response.data.status === 200) {
        setopenTost(true);
        setostMessage(deleteAttributeMessage);
        setType('success');
        fetchData()
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }


  return (
    <section id="admin-main-content">
      <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header border-bottom mb-4">
          <h5 id="offcanvasRightLabel" className="text-capatlise">{attributeType} attribute</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="mb-3">
            <label for="attributeExample" class="form-label">Name<span className="required">*</span></label>
            <input type="text" class="form-control" onChange={handleNameChange} id="attributeExample" placeholder="Enter Name" value={name} />
            <span className="error">{nameError && 'Name field is required'}</span>
          </div>
          <label for="forCategory" class="form-label">Select category<span className="required">*</span></label>
          <select class="form-select mb-3" aria-label="Default select example" onChange={categoryChange}>
            {categoryValue?.map(category => (
              <>
                <option value={category?._id} selected={categoryId === category?._id}>{category?.category_name}</option>
              </>
            ))}
          </select>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">Value <span className="notes">(value should be commom seprated.)</span></label>
            <textarea class="form-control" value={selectedUnit} onChange={(e) => setselectedUnit(e.target.value)} placeholder="example value1, value2,..etc." id="exampleFormControlTextarea1" rows="6"></textarea>
            <span className="error">{uniterror && 'Values field is required'}</span>
          </div>
        </div>
        <div class="offcanvas-footer border-top pt-4">
          <div className=" mb-4 d-flex justify-content-end mx-4">
            <button className="btn btn-secondary px-5 mx-2" onClick={addAttribute} type="button">{attributeType == 'Add' ? 'Save' : 'Update'}</button>
            <button className="btn btn-theam px-5" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="vendor-top">
            <h5 className="title-v">All Attributes</h5>
          </div>
        </div>
        <div className="row">
          <div className="card-header d-flex border-top rounded-0 flex-wrap py-0 flex-column flex-md-row align-items-start table-header-page">
            <div className="me-5 ms-n4 pe-5 mb-n6 mb-md-0">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Attribute"
                    aria-controls="DataTables_Table_0"
                  />
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
              <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                <div className="dataTables_length mx-n2" id="DataTables_Table_0_length">
                  <label>
                    <select
                      name="DataTables_Table_0_length"
                      aria-controls="DataTables_Table_0"
                      className="form-select"
                      id="entries"
                      value={selectedOption}
                      onChange={handleChange}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="70">70</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
                <div className="mb-6">
                  {role == 'admin' && <button className="btn btn-theam" onClick={() => openSideBar('add')} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <span>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      <span className="mx-2">Add Attribute</span>
                    </span>
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="mlr-10 table-responsive mb-4">
              <table ref={tableRef} className="table">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Category</th>
                    <th>Attribute Name</th>
                    <th>Values</th>
                    {role == 'admin' && <th style={{minWidth:85}}>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item?._id}</td>
                      <td style={{minWidth:200}}>{item?.category_id?.category_name}</td>
                      <td style={{minWidth:200}}>{item?.attribute_name}</td>
                      <td>
                        {item?.units}
                      </td>
                      {role == 'admin' && <td>
                        <button className="edit-btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => onEditAttribute(item)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button className="trash-icon" onClick={() => deleteAttribute(item._id)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-sm-12 col-md-6 mt-2">
            <div className="dataTables_info">
              Displaying {currentPage} to {Math.min(currentPage * selectedOption, totalItems)} of {totalItems} entries
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="dataTables_paginate">
              <ul className="pagination">
                <li
                  className={`paginate_button page-item previous ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <span
                    className="page-link"
                    onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                  >
                    <i className="fa fa-chevron-left bx-18px"></i>
                  </span>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`paginate_button page-item ${currentPage === index + 1 ? "active" : ""}`}
                  >
                    <span
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </span>
                  </li>
                ))}
                <li
                  className={`paginate_button page-item next ${currentPage === totalPages ? "disabled" : ""}`}
                >
                  <span
                    className="page-link"
                    onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                  >
                    <i className="fa fa-chevron-right bx-18px"></i>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllAttributes;
