import React from 'react';
import {Asset_url } from "../../../../api";


const VendorKYC = ({Vendordata, _handleKYCUpdate, handleModal}) => {
    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor KYC</h5>
                </div>
            </div>
            <div className='tableGap'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='custom-cart-panel mb-3'>
                            <div className="vendorProfile justify-content-between">
                                <h5 className='mb-3'>KYC Details {Vendordata?.isKyc && <span className='badge'>Approved</span>}</h5>
                                {!Vendordata?.isKyc && <div className="vendorProfile">
                                    <button className="btn btn-theam mx-2" onClick={()=>_handleKYCUpdate('kyc', 'approved')}>
                                        <span>Approve</span>
                                    </button>
                                    <button className="btn btn-theam mx-2" onClick={()=>handleModal('kyc')}>
                                        <span>Reject</span>
                                    </button>
                                </div>}
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <h6>Government Id</h6>
                                    <div className="vendorProfile">
                                        <div className=''>
                                            {Vendordata?.government_id ? <a href={`${Asset_url}`+Vendordata?.government_id} target='_blank'><img src={`${Asset_url}`+Vendordata?.government_id} alt='' /></a> : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <h6>Address Proof</h6>
                                    <div className="vendorProfile">
                                        <div className=''>
                                            {Vendordata?.address_proof ? <a href={`${Asset_url}`+Vendordata?.address_proof} target='_blank'><img src={`${Asset_url}`+Vendordata?.address_proof} alt='' /></a> :'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <h6>Live Photo</h6>
                                    <div className="vendorProfile">
                                        <div className=''>
                                            {Vendordata?.live_photo ? <a href={`${Asset_url}`+Vendordata?.live_photo} target='_blank'><img src={`${Asset_url}`+Vendordata?.live_photo} alt='' /></a> : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorKYC;