import React, { useEffect, useState } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import Profile from "../../componenth/profile/Profile";
import { REACT_BASE_PATH, Asset_url } from "../../api";
import axios from "axios";
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const ProfileInfo = () => {
  useEffect(() => {
    //import('./WebPanelRoutes.css');
  }, [])

  const accessToken = Cookies.get('krizaar-user');
  const userId = localStorage.getItem("userId");

  const [displayImage, setDisplayImage] = useState(null);

  useEffect(() => {
    if (!accessToken || !userId) {
      navigate("/signin");
      return;
    }
  }, [])

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mobno, setMobno] = useState();
  const [dob, setDob] = useState(new Date());
  const [gender, setGender] = useState("");
  const [profilePic, setProfilePic] = useState();

  const navigate = useNavigate();

  const submitData = () => {
    if (!firstName || !lastName) {
      toast.warning('Please enter first name and last name')
      return;
    }

    const formData = new FormData();
    formData.append('id', userId);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('dob', dob);
    formData.append('phone_number', mobno);
    formData.append('gender', gender);
    formData.append('profile_image', profilePic);

    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    axios
      .post(`${REACT_BASE_PATH}/auth/user/profile`, formData, { headers })
      .then((response) => {
        // console.log(response.data);
        toast.success('Your details have been saved')
        // navigate('/')
      })
      .catch((error) => {
        // console.error("Error:", error);
        toast.error(error.response?.data?.message || "Some error occured, please try again");
      });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!accessToken || !userId) {
        navigate("/signin");
        return;
      }

      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/auth/user/profile/detail`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            }
          }
        );
        // console.log(response.data.userdata);
        if (response.data && response.data.userdata) {
          setFirstName(response.data.userdata.first_name);
          setLastName(response.data.userdata.last_name);
          setEmail(response.data.userdata.email);
          setMobno(response.data.userdata.phone_number);
          setDob(response.data.userdata.dob ? new Date(response.data.userdata.dob) : new Date());
          setGender(response.data.userdata.gender==="male" ? "0" : "1");
          setDisplayImage(`${Asset_url}${response.data.userdata.profile_image}`)
        }
      } catch (e) {
        // console.log(e, "Failed to fetch user details due to error: ", e.message)
        toast.error(e.response?.data?.message || "Some error occured, please try again");
      }
    }

    fetchUserDetails();
  }, [])

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setDisplayImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-3">
              <Profile />
            </div>
            <div className="col-lg-9">
              <div>
                <span>Account {">"} Profile Information</span>
                <div className="user-profile-image">
                  <label htmlFor="file-upload" className="custom-file-upload d-flex flex-column">
                  <img
                    src={displayImage}
                    alt="Profile Pic"
                  />
                    Upload Profile Pic
                  </label>
                  <input id="file-upload" type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} />

                </div>
                <div className="form-row">
                  <span className="inp-form">
                    <label htmlFor="input1">First Name</label>
                    <input
                      type="text"
                      id="input1"
                      name="input1"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </span>
                  <span className="inp-form">
                    <label htmlFor="input2">Last Name</label>
                    <input
                      type="text"
                      id="input2"
                      name="input2"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </span>
                </div>
                <div className="form-row">
                  <span className="inp-form">
                    <label htmlFor="input1">Email Address</label>
                    <input
                      type="text"
                      id="input1"
                      name="input1"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </span>
                  <span className="inp-form">
                    <label htmlFor="input2">Mobile no.</label>
                    <input
                      type="text"
                      id="input2"
                      name="input2"
                      value={mobno}
                      onChange={(e) => setMobno(e.target.value)}
                    />
                  </span>
                </div>
                <div class="form-row">
                  <span className="inp-form">
                    <label htmlFor="input1" style={{zIndex: '99'}}>DOB</label>
                    {/* <input
                      type="text"
                      id="input1"
                      name="input1"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    /> */}
                    <DatePicker selected={dob} onChange={(date) => setDob(date)} />
                  </span>
                </div>
                <div>
                  <button className="male-btn">
                    <input
                      type="radio"
                      name="gender"
                      value="0"
                      checked={gender === "0"}
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <br />
                    Male
                  </button>
                  <button className="female-btn">
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      checked={gender === "1"}
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <br />
                    Female
                  </button>
                </div>
                <p className="mt-3">
                  In order to access some features of the Service, you will have
                  to fill out your account details. You may use it for buying
                  things also for shipping process for more details
                </p>
                <div className="form-btn">
                  <button
                    className="f-next"
                    onClick={() => {
                      submitData();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileInfo;
