import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../header/Footer";
import { useNavigate, useParams } from "react-router-dom";
// import('../../components/WebPanel/WebPanelRoutes.css');
import axios from 'axios'
import { REACT_BASE_PATH } from "../../api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from 'js-cookie'
import { toast } from "react-toastify";


const Resetpassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('')
  const [cpassword, setCPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    const userToken = Cookies.get('krizaar-user');
    const userId = localStorage.getItem('userId');

    if ((userToken && userId) || !token) {
      navigate("/");
      return;
    }

    const forgotPasswordTimestamp = localStorage.getItem("forgotPasswordTimestamp");
    if (!forgotPasswordTimestamp) {
      toast.error("Forgot password session expired. Please try again.");
      navigate("/forgot-password");
      return;
    }

    const currentTime = new Date().getTime();
    const timeDifference = currentTime - forgotPasswordTimestamp;
    const tenMinutesInMillis = 10 * 60 * 1000;

    if (timeDifference > tenMinutesInMillis) {
      toast.error("Time limit exceeded. Please initiate the forgot password process again.");
      localStorage.removeItem("forgotPasswordTimestamp");
      navigate("/forgot-password");
      return;
    }
  }, [token, navigate])

  const submitData = async (e) => {
    e.preventDefault();
    if (!password || !cpassword) {
      toast.warning('Please fill both password fields')
      return
    }
    if (password !== cpassword) {
      toast.warning('Passwords do not match')
      return
    }

    try {
      const response = await axios.post(`${REACT_BASE_PATH}/auth/user/reset-password`, { token, password })
      console.log(response)
      if (response.status === 200) {
        toast.success('Password reset successfully. You can now login with your new password.')
        navigate('/signin')
      }
    } catch (err) {
      console.log("error", err);
      toast.error(err.response?.data?.msg)
    }
  };
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="signin-box">
              <div className="signin-box1">
                <h4 className="text-center mt-5">Krizaar.com</h4>
                <div className="sign-form">
                  <h5 className="mb-4">Reset Password</h5>
                  <form onSubmit={submitData}>
                    <label htmlFor="email">Enter your new password</label>
                    <div className="password-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className=""
                        placeholder=""
                        required
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <br />
                    <label htmlFor="email">Confirm your password</label>
                    <div className="password-input">
                      <input
                        type={showCPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={cpassword}
                        onChange={(e) => setCPassword(e.target.value)}
                        className=""
                        placeholder=""
                        required
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={() => setShowCPassword(!showCPassword)}
                      >
                        {showCPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <br />
                    <br />
                    <div className="sbtn-box">
                      <button
                        type="submit"
                        className="sub-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <hr className="mt-4" />
            <div className="amazon-box">
              <span className="amazon">or</span>
            </div>
            <div className="create-acc-box">
              <button className="create-acc">Get an OTP on your Phone</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Resetpassword;
