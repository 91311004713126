import {
  Avatar,
  Box,
  Chip,
  FormControl,
  LinearProgress,
  styled,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  _get_image_path,
  _get_task_history,
  _update_task_history,
} from "../../../../../url";
import { getAdminId } from "../../../../../utills";
import axios from "axios";
import { purple } from "@mui/material/colors";
import { alpha } from "@material-ui/core";
import toast from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ImageSeeModal from "../../../../AirdropDashboardPages/component/ImageSeeModal";

function TaskHistory() {
  const [labels, setLabels] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [updated, setUpdated] = useState("action");
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [openDelteModal, setOpenDelteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState("");

  useEffect(() => {
    const fetchLabels = async () => {
      setIsFetching(true);
      try {
        const response = await axios.post(_get_task_history, {
          id: getAdminId(),
        });
        
        if(Array.isArray(response.data.data)) {
          const nonNullData = response.data.data?.filter(data => data.taskId !== null)
          setLabels(nonNullData || []); // Adjust based on your API response structure
        } else {
          setLabels([{...response.data.data}]);
        }
        setIsFetching(false);
      } catch (error) {
        console.error("Error fetching labels:", error);
      }
    };
    fetchLabels();
  }, [updated]);

  const handleUpdateStatus = async (data,status) => {
    setIsUpdating(true);
    try {
      const response = await axios.post(_update_task_history, {
        id: getAdminId(),
        taskHistoryId: data?._id,
        status: status,
        reason : status === false ? reason : null
      });
      toast.success("Status Updated Successfully..");
      setLabels(response.data.data || []); // Adjust based on your API response structure
      setIsUpdating(false);
      setEditUser(null);
      setOpenModal(false)
      setUpdated(!updated)
    } catch (error) {
      console.error("Error fetching labels:", error);
      toast.error("Error in updating..");
      setIsUpdating(false);
      
    }
  };

  


  const handleModal = (data) => {
    setOpenModal(!openModal);
    setEditUser(data);
  };

  const onHandleSeeImage = (img) => {
    setEditUser(img);
    setOpen(true);
 }

 const onRejectHandler = (e) => {
   e.preventDefault();
   handleUpdateStatus(editUser, false)
 }

  return (
    <>
      {(isFetching || isUpdating) && <LinearProgress color="secondary" />}
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Task History</h5>
              <div className="button-right-side">
                <div className="addBrands">{/* <BrandPopup /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="custom-cart-panel  mb-3">
        <div className="d-flex flex-row justify-content-between">
          <Box className="d-flex align-items-center gap-2 w-25">
            <FormControl fullWidth>
              <input
                // value={codeVal}
                // onChange={(e) => setcode(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Search Affiliates"
                required
              />
            </FormControl>
          </Box>
          <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
            <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
              <div
                className="dataTables_length mx-n2"
                id="DataTables_Table_0_length"
              >
                <label>
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    className="form-select"
                    id="entries"
                    // value={selectedOption}
                    // onChange={handleChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="70">70</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
              <div className="mb-6">
                <button className="btn btn-theam mx-2" type="button">
                  <span>Export CSV</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mlr-10 mb-4">
          {isFetching ? (
            "Fetching History..."
          ) : (
            <table className="tableGap">
              <thead>
                <tr>
                  <th>Task Name</th>
                  <th>Project Name</th>
                  <th>Task URL</th>
                  <th>Task User</th>
                  <th>Avidence</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {labels?.length === 0 && (
                  <p className="p-2">No task history found</p>
                )}
                {labels?.map((lable) => (
                  <tr key={lable?._id}>
                    <td>{lable?.taskId?.taskName}</td>
                    <td>{lable?.taskId?.taskTabel?.labelName}</td>
                    <td>{lable?.taskId?.taskUrl}</td>
                    <td>{lable?.userId?.name}</td>   
                    <td>
                      <Avatar
                        alt="Remy Sharp"
                        src={`${_get_image_path}/${lable?.image}`}
                        onClick={() =>
                          onHandleSeeImage(`${_get_image_path}/${lable?.image}`)
                        }
                      />
                    </td>
                    <td>
                      {new Date(lable?.createdAt).toLocaleTimeString()}-
                      {new Date(lable?.createdAt).toLocaleDateString()}
                    </td>
                    <td>
                    <td>
                        {
                            lable?.isApproved && <Chip label="Approved" color="success" />
                        }
                        {
                            lable?.isApproved === false && <Chip label="Rejected" className="bg-danger" />
                        }
                        {
                            lable?.isApproved === null && <Chip label="Pending" color="warning" />
                        }
                    </td>
                    </td>
                    <td>
                      <button
                        className="btn btn-theam mx-2"
                        onClick={(e) => handleUpdateStatus(lable, true)}
                      >
                        <span>Approve</span>
                      </button>
                      <button
                        className="btn btn-theam mx-2"
                        onClick={() => handleModal(lable)}
                      >
                        <span>Reject</span>
                      </button>
                    </td>
                  </tr>
                ))}

                {/* {users?.map((data, index) => (
                  <tr key={data?._id}>
                    <td>{index + 1}.</td>
                    <td>{data?.categoryId?.category_name}</td>
                    <td>{data?.name}</td>
                    <td>{data?.affiliatePercentage}</td>
                    <td>
                      <PinkSwitch
                        {...label}
                        checked={data?.isAffiliateUser}
                        onChange={(event) => handleUpdateStatus(event,data?._id)}
                      />
                    </td>
                    <td className="d-flex items-center">
                      <button
                        className="edit-btn"
                        onClick={() => handleClickOpen(data)}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="trash-icon disabledBtn" onClick={() => handleDeleteUser(data)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          )}
        </div>
        <Dialog
          open={openModal}
          keepMounted
          maxWidth={"xs"}
          onClose={handleModal}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Reject Reason"}</DialogTitle>
          <form onSubmit={onRejectHandler}>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Please enter a reason to cancel this approvel
              </DialogContentText>
              <div class="mb-3">
                {/* <label for="exampleFormControlInput1" class="form-label">Reason for cancel </label> */}
                <textarea
                  rows={3}
                  type="text"
                  class="form-control"
                  id="categoryNameId"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <button className="btn px-3 py-2" onClick={handleModal}>
                Cancel
              </button>
              <button
                type="submit"
                className="edit-btn px-3 py-2"
                // onClick={() => _handleKYCUpdate(Updatetype, "rejected")}
              >
                Update
              </button>
            </DialogActions>
          </form>
        </Dialog>
        <ImageSeeModal
          open={open}
          onClose={() => setOpen(false)}
          editUser={editUser}
        />
      </section>
    </>
  );
}

export default TaskHistory;
