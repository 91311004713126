import React from 'react'
import CommonOtherLinkComponent from './krizaar_pay/CommonOtherLinkComponent'

const AdJunction = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Global Reach',
      content: 'Connect with millions of potential customers across North America and Asia. Our platform gives your brand the visibility it needs in diverse, high-value markets, making it easier than ever to engage with audiences ready to embrace your products.',
      image: '/images/adjunction/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'Break Into the Asian Market',
      content: "Overcome the barriers that have traditionally made it difficult to reach Indian and Asian consumers. With the Ad Junction, you have direct access to one of the world's most vibrant and growing markets, where demand for new products and services is soaring.",
      image: '/images/adjunction/pic3.jpg'
    },
    {
      id: 3,
      subtitle: 'Web2/Web3 Integration',
      content: 'Harness the best of both worlds. Our unique hybrid platform seamlessly combines traditional and decentralized commerce, ensuring your ads reach your target audience—no matter where they are in the digital landscape.',
      image: '/images/adjunction/pic4.jpg'
    },
    {
      id: 4,
      title: 'Social Media Advertising Opportunities',
      subtitle: 'Global Engagement',
      content: 'Tap into a vibrant social media ecosystem designed to captivate users worldwide. Whether you’re reaching out to trendsetters in North America or emerging influencers in Asia, our social platform delivers your message where it matters most.',
      image: '/images/adjunction/pic5.jpg'
    },
    {
      id: 5,
      title: 'Engage and Captivate Audiences Across the Globe',
      subtitle: 'Interactive Campaigns',
      content: `Go beyond basic advertising—create campaigns that truly engage. Use interactive content to captivate your audience, boost engagement rates, and build lasting brand loyalty. Our proprietary patent pending social platforms task board enables you to command and direct an influencer network to target specific tasks that enable instant virality of brands on a mass scale.`,
      image: '/images/adjunction/pic6.jpg'
    }
      
  ]

  const heroSectionItems = {
    title: 'Ready to take your brand global?',
    subtitle: 'Advertise with Ad Junction: Amplify Your Brand Globally',
    content: 'Unlock a World of Opportunities—Connect with Millions Across Web2 and Web3',
    content2: "At Ad Junction, we're not just a marketplace—we're a dynamic bridge between web2 and web3 commerce. Whether you're targeting the lucrative North American market or the rapidly expanding Asian consumer base, Ad Junction is your gateway to unparalleled success.",
    image: '/images/adjunction/pic1.jpg'
  }

  const middleBannerContent = {
    title: "Blockchain-Powered Advertising",
    subtitle: "Advertise with Confidence—Transparency and Control at Your Fingertips",
    steps: [
      {
        title: `Real-Time Campaign Management`,
        description: `Monitor and control your campaigns with complete transparency. Our blockchainpowered platform gives you real-time insights into ad placement, budget allocation, and performance, so you can optimize your strategy on the fly.`,
      },
      {
        title: `Cost-Effective Advertising`,
        description: `Say goodbye to hidden fees and unexpected costs. With smart contracts, you set the terms and only pay for what you get—ensuring a straightforward, budget-friendly advertising experience.`,
      },
      {
        title: `Secure, Fast Payments`,
        description: `Streamline your financial processes with fast, secure payments powered by blockchain technology. Focus on growing your brand while we handle the rest with transparent and efficient payment remittances.`,
      }
    ],
  };
  
  return (
    <>
      <CommonOtherLinkComponent title="Why Advertise with Ad Junction?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} />
    </>
  )
}

export default AdJunction
