import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';
import axios from 'axios';
import { _delete_task, _delete_task_table } from '../../../../../url';
import toast from 'react-hot-toast';
import { getAdminId } from '../../../../../utills';

function DeleteModal({for:actionFor,open, onClose, editUser, setRemount}) {
    const [isPending, setIsPending] = useState(false);
     

  const handleDelete = async () => {
    let formData = {}
    if(actionFor === 'CREATE_TASK_LABLE') {
        formData.tabelId = editUser._id;
    } else {
        formData.taskId = editUser._id;
    }

    try {
        const response = await axios.post(actionFor !== 'CREATE_TASK' ? _delete_task_table : _delete_task  , { id: getAdminId(), ...formData });
        console.log('Form Submitted Successfully:', response.data);
        toast.success('Deleted successfully!');
       
        onClose(); // Close the drawer after submission
        setRemount(prev => !prev)
    } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Failed to update Please try again.');
    }
  }  

  return (
    <Dialog open={open} onClose={onClose}>
          {isPending && <LinearProgress color="secondary" /> }
        <DialogTitle>Delete Task / Label</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete()} color="primary" disabled={isPending}>
            {isPending ? 'Deleting...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default DeleteModal