import React, { useState } from "react";

function Pagination({
  selectedOption,
  currentPage,
  setCurrentPage,
  totalItems,
}) {
  
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
 
  const totalPages = Math.ceil(totalItems / selectedOption);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="row mb-4">
      <div className="col-sm-12 col-md-6 mt-2">
        <div className="dataTables_info">
          Displaying {currentPage} to{" "}
          {Math.min(currentPage * selectedOption, totalItems)} of {totalItems}{" "}
          entries
        </div>
      </div>
      <div className="col-sm-12 col-md-6">
        <div className="dataTables_paginate">
          <ul className="pagination">
            <li
              className={`paginate_button page-item previous ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <span
                className="page-link"
                onClick={() =>
                  currentPage > 1 && handlePageChange(currentPage - 1)
                }
              >
                <i className="fa fa-chevron-left bx-18px"></i>
              </span>
            </li>
            {[...Array(totalPages)]?.map((_, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <span
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </span>
              </li>
            ))}
            <li
              className={`paginate_button page-item next ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <span
                className="page-link"
                onClick={() =>
                  currentPage < totalPages && handlePageChange(currentPage + 1)
                }
              >
                <i className="fa fa-chevron-right bx-18px"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
