import React from 'react';
import { formatDate } from '../../helper/DateTimeFormat';
import { useNavigate } from 'react-router-dom';

const UserrOrders = ({ data }) => {
    const navigate = useNavigate()

    const navigateToOrderDetail = (order) => {
        navigate('../OrderDetails/', { state: order })
    }

    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">User Order List</h5>
                </div>
            </div>
            <div className='tableGap'>
                <table>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Order Date</th>
                            <th>Payment Status</th>
                            <th>Total Price</th>
                            <th>Order Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    {item?._id}
                                </td>
                                <td>
                                    {formatDate(item?.orderDate)}
                                </td>
                                <td>{item?.paymentStatus}</td>
                                <td>Rs. {item?.totalPrice}</td>
                                <td>{item?.status}</td>
                                <td>
                                    <div className="btn-group border-0">
                                        <button className="edit-btn" onClick={() => navigateToOrderDetail(item?._id)}>
                                            <i className="fa fa-eye"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UserrOrders;