import React from 'react'
import Header from '../../../componenth/header/Header'
import Footer from '../../../componenth/header/Footer'
import './category.css'
import CategoryProducts from './CategoryProducts'
import Hero from '../../../componenth/common/Hero'


const Category = () => {
  return (
    <>
      <Hero/>
      <CategoryProducts/>
    </>
  )
}

export default Category
