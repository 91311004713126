import React from 'react';
import { FaTimes } from 'react-icons/fa';

const SettingsModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h3>Settings</h3>
                    <button onClick={onClose}>
                        <FaTimes />
                    </button>
                </div>
                <div className="settings-modal-body">
                    <div className="settings-section">
                        <h5 className='mb-3'>Swaps & Liquidity</h5>
                        <p className='mb-2'>Default Transaction Speed (GWEI)</p>
                        <div className="option-buttons mb-3">
                            <button>Default</button>
                            <button>Standard (1)</button>
                            <button>Fast (4)</button>
                            <button>Instant (5)</button>
                        </div>
                        <p className='mb-2'>Slippage Tolerance</p>
                        <div className="option-buttons mb-3">
                            <button>0.1%</button>
                            <button>0.5%</button>
                            <button>1.0%</button>
                            <button>1.50</button>
                        </div>
                        <div className=' d-flex align-items-center justify-content-between'>
                            <p>Tx deadline (mins):</p>
                            <div className="option-buttons mb-3">
                                <button>0.5</button>
                            </div>
                        </div>
                        <div className="toggle-options mb-3">
                            <div className='form-switch'>
                                <p>Expert Mode</p>
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"></input>
                            </div>
                            <div className='form-switch'>
                                <p>Flippy Sounds</p>
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"></input>
                            </div>
                            <div className='form-switch'>
                                <p>Fast Routing (BETA)</p>
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"></input>
                            </div>
                        </div>
                        <button className="save-settings-btn">Save Settings</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsModal;
