import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import icon1 from "../../img/Button-1.png";
import icon2 from "../../img/Button-2.png";
import icon3 from "../../img/3D.png";
import "./header.css";
import banner from "../../img/productsale.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();
  const [showFooterBanner, setShowFooterBanner] = useState(true);

  useEffect(() => {
    if (pathname === '/pay' || pathname === '/logistics') {
      setShowFooterBanner(false);
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  if (
    pathname === '/affliateDashboard'

  ) {
    return null
  }

  return (
    <>
      {/* {showFooterBanner && <section>
        <div className="container">
          <div className="row my-5">
            <img src={banner} alt="footer-banner" className="img-fluid" />
          </div>
        </div>
      </section>} */}

      {/* {showFooterBanner && <section>
        <div className="container px-3">
          <div className="row andriod-row">
            <div className="col-lg-8">
              <div className="andriod-btn">
                <img src={icon1} alt="andriod-btn" className="img-fluid" />
                <img src={icon2} alt="andriod-btn" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="andriod-btn1">
                <img
                  src={icon3}
                  alt="andriod-btn"
                  className="img-fluid three-d"
                />
              </div>
            </div>
          </div>
        </div>
      </section>}

      {showFooterBanner && <section className="newsletter-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 text-center newsletter-content">
              <h2 className="sign-ft">Sign up for Newsletter</h2>
              <p>Shopping First for Coupon $20, Receive...</p>
              <form className="newsletter-form">
                <input type="email" placeholder="info@krizaar.com" />
                <button type="submit">Subscribe</button>
              </form>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>} */}

      <section className="footer pt-1">
        <div className="container-fluid">
          <div className="row footer-row">
            <div className="col-lg-4">
              <p className="sec-store">Store information</p>
              <div className="foot-info d-flex align-items-center">
                <i className="fa fa-map-marker ft-icon" aria-hidden="true"></i>
                <span>
                  700 Rosemary Ave, Ste 204
                  <br />
                  701 West Palm Beach. Fl 33401
                </span>
              </div>
              <div className="foot-info">
                <i className="fa fa-phone ft-icon"></i>
                <NavLink to="tel:+18885749227" className="ft-link">
                  1888 KRIZAAR (1 888-574-9227)
                </NavLink>
              </div>
              <div className="foot-info">
                <i className="fa fa-envelope ft-icon" aria-hidden="true"></i>
                <NavLink to="mailto:info@krizaar.com" className="ft-link">
                  info@krizaar.com
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 f-info">
              <p className="sec-store">Quick links</p>
              <ul>

                <NavLink to="/sellersclub" className="ft-link">
                  <li className="foot-info">Krizaar Sellers Club</li>
                </NavLink>
                <NavLink to="/krizadrops" className="ft-link">
                  <li className="foot-info">Kriza Drops</li>
                </NavLink>
                <NavLink to="/adjunction" className="ft-link">
                  <li className="foot-info">Krizaar Ad Junction</li>
                </NavLink>
                <NavLink to="/affluence" className="ft-link">
                  <li className="foot-info">Krizaar Affluence</li>
                </NavLink>
                <NavLink to="/compliance" className="ft-link">
                  <li className="foot-info">Krizaar Compliance</li>
                </NavLink>
                <NavLink to="/logistics" className="ft-link">
                  <li className="foot-info">Krizaar Logistics</li>
                </NavLink>
                <NavLink to="/pay" className="ft-link">
                  <li className="foot-info">Krizaar Pay</li>
                </NavLink>
                <NavLink to="/swap" className="ft-link">
                  <li className="foot-info">Krizaar Swap</li>
                </NavLink>
                <NavLink to="/stake" className="ft-link">
                  <li className="foot-info">Krizaar Stake</li>
                </NavLink>
              </ul>
            </div>
            <div className="col-lg-2 f-info">
              <p className="sec-store">My Account</p>
              <ul>
                <NavLink to="/userprofile" className="ft-link">
                  <li className="foot-info">My Profile</li>
                </NavLink>
                <NavLink to="/cart" className="ft-link">
                  <li className="foot-info">Cart</li>
                </NavLink>
                <NavLink to="/whishlist" className="ft-link">
                  <li className="foot-info">Wishlist</li>
                </NavLink>
                <NavLink to="/affiliate_login" className="ft-link">
                  <li className="foot-info">Affiliate Login</li>
                </NavLink>
              </ul>
            </div>
            <div className="col-lg-2 f-info">
              <p className="sec-store">Products</p>
              <ul>
                <li className="foot-info">All collection</li>
              </ul>
            </div>
            <div className="col-lg-2 f-info">
              <p className="sec-store">Our company</p>
              <ul>
                <NavLink to="/about-us" className="ft-link">
                  <li className="foot-info">About Us</li>
                </NavLink>
                <NavLink to="/contactus" className="ft-link">
                  <li className="foot-info">Contact Us</li>
                </NavLink>
                <NavLink to="/faqpage" className="ft-link">
                  <li className="foot-info">FAQs</li>
                </NavLink>
                <NavLink to="/privacy-policy" className="ft-link">
                  <li className="foot-info">Privacy Policy</li>
                </NavLink>
                <NavLink to="/cookies-policy" className="ft-link">
                  <li className="foot-info">Cookies Policy</li>
                </NavLink>
                <NavLink to="/sanctions-policy" className="ft-link">
                  <li className="foot-info">Sanctions Policy</li>
                </NavLink>
                <NavLink to="/interest-based-ads" className="ft-link">
                  <li className="foot-info">Interest Based Ads</li>
                </NavLink>
                <NavLink to="/electronic-communication-policy" className="ft-link">
                  <li className="foot-info">Electronic Communication Policy</li>
                </NavLink>
                <NavLink to="/terms-of-service" className="ft-link">
                  <li className="foot-info">Terms of Service</li>
                </NavLink>
                <NavLink to="/return-refund" className="ft-link">
                  <li className="foot-info"> Return & Refund</li>
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="row copy-right">
            <p>
              Copyright 2024 Design & Developed By Dreamvessels Technology Pvt.
              Ltd. Powered by Krizaar
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
