import React, { useEffect, useState } from 'react'
import Header from "../AdminPanel/component/Header";
import { Route, Routes, useNavigate } from 'react-router-dom';
import Sidebar from '../AffilateDashboardPages/Sidebar';
import Dashboard from '../AffilateDashboardPages/Dashboard';
import Products from '../AffilateDashboardPages/Products';
import Ladger from '../AdminPanel/component/affilateDashboard/Ladger';
import { isJwtExpired } from 'jwt-check-expiration';
import { getAffilateAccessToken } from '../../utills';
import toast from 'react-hot-toast';
import History from '../AffilateDashboardPages/History';
import Wallet from '../AffilateDashboardPages/Wallet';

function AffilateDashboardRoutes() {
      useEffect(() => {
        import('../../components/AdminPanel/AdminPanelRoutes.css');
      }, [])
    const [collapsed, setCollapsed] = useState(false);
    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };
    const navigate = useNavigate();

    useEffect(() => {
      if(isJwtExpired(getAffilateAccessToken())) {
        toast.error("Token Has Expired,you need to login again");
        setTimeout(() => {
          navigate('/affiliate_login');  
        },[])
      }
    },[navigate])

  return (
    <div className="app admin-panel-body">
    <Header collapsed={collapsed} onToggle={toggleSidebar} />
    <div className="main-container">
        <Sidebar
            collapsed={collapsed}
        />
        <div className="main-content">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} /> 
              <Route path="/products" element={<Products />} />
              <Route path="/history" element={<History role="affiliate" />} />
              <Route path="/wallet" element={<Wallet />} />
              {/* <Route path="*" element={<NotFound />} />  */}
            </Routes>
        </div>
    </div>
</div>
  )
}

export default AffilateDashboardRoutes