import React, { useState } from 'react'
import aadhar from '../../img/aadhar.png'
import pan from '../../img/pan.jpg'
import shop1 from '../../img/shop1.webp'
import shop2 from '../../img/shop2.jpg'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import shop3 from '../../img/shop3.avif'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AffiliateProfile = ({Vendordata}) => {
    const [dropdownList, setdropdownList] = useState(['Approve', 'Not Approve'])
    const [openModal, setOpenModal] = useState(false);

    const handleModalOpen = (option) => {
        if (option !== 'Approve') {
            setOpenModal(true)
        }
    }

    const handleModal = () => {
        setOpenModal(false)
    }

    console.log("Vendordata",Vendordata)

    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Affiliate Profile</h5>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='custom-cart-panel mb-3'>
                        <h5 className='mb-3'>Affiliate Address</h5>
                        <h6>{Vendordata?.city}, {Vendordata?.state}, {Vendordata?.country}, {Vendordata?.pincode}</h6>
                    </div>
                </div>
            </div>
            <div className='custom-cart-panel mb-3'>
                <div className="row">
                    <div className="vendor-top">
                        <h5 className="title-v">Change Password</h5>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="mb-2">
                            <label for="exampleFormControlInput1" className="form-label">Password</label>
                            <input type="text" className="form-control" id="categoryNameId" placeholder="password" />
                        </div>
                        <div className="mb-4">
                            <label for="exampleFormControlInput1" className="form-label">Confirm Password</label>
                            <input type="text" className="form-control" id="categoryNameId" placeholder="confirm password" />
                        </div>
                        <button className="trash-icon px-3 py-2" >Change Password</button>
                    </div>
                </div>
            </div>
            <Dialog
                open={openModal}
                keepMounted
                maxWidth={'xs'}
                onClose={handleModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Vendor Approvel Cancel"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Please enter a reason to cancel this approvel...
                    </DialogContentText>
                    <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">Reason for cancel </label>
                        <textarea rows={3} type="text" className="form-control" id="categoryNameId" placeholder="Enter reason for cancel" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="trash-icon px-3 py-2" onClick={handleModal}>Cancel</button>
                    <button className="edit-btn px-3 py-2" onClick={handleModal}>Continue</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AffiliateProfile;