import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const WalletConnectModal = ({ isOpen, onClose, onWalletSelect }) => {
    if (!isOpen) return null;

    const handleWalletSelect = (wallet) => {
        onWalletSelect(wallet);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <div className='text-start'>
                        <h3>Connect a Wallet</h3>
                        <p>Choose one of the providers below</p>
                    </div>
                    <button onClick={onClose}>X</button>
                </div>
                <div className="modal-body">
                    <div className="wallet-option" onClick={() => handleWalletSelect('Metamask')}>
                        <div className='wallet-details'>
                            <img src="/images/meta-mask.png" alt="Metamask" />
                            <p>Metamask</p>
                        </div>
                        <span className='right-arrow'><FaArrowRight /></span>
                    </div>
                    <div className="wallet-option" onClick={() => handleWalletSelect('Binance')}>
                        <div className='wallet-details'>
                            <img src="/images/BNB2.png" alt="Binance Chain Wallet" />
                            <p>Binance Chain Wallet</p>
                        </div>
                        <span className='right-arrow'><FaArrowRight /></span>
                    </div>
                    <div className="wallet-option" onClick={() => handleWalletSelect('WalletConnect')}>
                        <div className='wallet-details'>
                            <img src="/images/walletconnect.png" alt="Wallet Connect" />
                            <p>Wallet Connect</p>
                        </div>
                        <span className='right-arrow'><FaArrowRight /></span>
                    </div>
                    <div className="wallet-option" onClick={() => handleWalletSelect('Portis')}>
                        <div className='wallet-details'>
                            <img src="/images/portis.png" alt="Portis" />
                            <p>Portis</p>
                        </div>
                        <span className='right-arrow'><FaArrowRight /></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WalletConnectModal;
