import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className=' d-flex flex-column align-items-center gap-3' style={{textAlign:'center',margin:100}}>
            <h1>404</h1>
            <h4>Oops Page Not Found...</h4>
            <button className='checkout__btn checkout__save-btn' onClick={() => navigate('/')}>Go to Home</button>
        </div>

    )
}

export default NotFound;