import React, { useEffect, useRef, useState } from "react";
import { REACT_BASE_PATH } from "../../../../api";
import axios from "axios";
import { Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import SnackBar from '../../helper/SnackBar';
import AllCategories from './AllCategories';
import { addCategoryMessage, deleteCategoryMessage } from "../ResponseMessage";

const AddCategories = () => {
  const [name, setName] = useState('');
  const inputRef = useRef()
  const [image, setImage] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [openTost, setopenTost] = useState(false);
  const [tostMessage, setostMessage] = useState('');
  const [type, setType] = useState('');
  const [categoryType, setCategotyType] = useState('Add')
  const [cate, setCat] = useState(false)
  const [validate, setvalidate] = useState(false);
  const [categoryid, setcategoryid] = useState('')
  const vendor = JSON.parse(localStorage.getItem('vendor'))
  const { role } = vendor;

  const handleNameChange = (event) => {
    if (event.target.value) {
      setNameError(false);
    }
    setName(event.target.value);
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setImgError(false);
    }
    setImage(event.target.files[0]);
  };

  const addCategoryClick = async (type) => {
    if (type == 'Add') {
      setCategotyType(type)
    }
    if (!name) {
      setNameError(true);
      return;
    }
    if (!image) {
      setImgError(true);
      return;
    }
    let userData = localStorage.getItem("user");
    userData = JSON.parse(userData);
    const formData = new FormData();
    formData.append('category_name', name);
    formData.append('category_image', image);
    formData.append('level', 0);
    formData.append("vendor_id", userData?.vendorid);
    if(categoryType == 'Edit'){
      formData.append('category_id',categoryid)
    }
    await fetchCategory(formData);
  };

  const fetchCategory = async (formData) => {
    let endpoint = categoryType == 'Add'?'/product/add/productcategory':'/product/update/productcategory'
    try {
      const response = await axios.post(`${REACT_BASE_PATH}`+endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        inputRef.current.value = ''
        setopenTost(true);
        setostMessage(addCategoryMessage);
        setType('success');
        setCat(true)
        setName('');
        setImage(null)
      } else {
        setopenTost(true);
        setostMessage(response?.message);
        setType('warning');
        console.error('Failed to add brand:', response.data);
      }
    } catch (error) {
      setopenTost(true);
      setostMessage(error.message);
      setType('error');
      console.error("Error adding brand:", error);
    }
  };

  const editCategoryClick = (item) => {
    setName(item.category_name)
    setImage(item.category_image)
    setcategoryid(item._id)
    setCategotyType('Edit')
  }

  const deleteCategory = async(id) => {
    const payload = {
      category_id:id
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/delete/productcategory`, payload);
      if (response.data.status === 200) {
        setopenTost(true);
        setostMessage(deleteCategoryMessage);
        setType('success');
        setCat(!cate)
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }

  useEffect(() => {
    if (!name) {
      setvalidate(false)
    } else if (!image) {
      setvalidate(false)
    } else {
      setvalidate(true)
    }
  }, [name, image])


  return (
    <>
      <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
      <div className="m-2 mt-24 md:m-10 p-2 md:p-10 comp-bg">
        <section className="main-content-body bg-white" id="main_admin_content">
          <div className="container-fluid">
            <div className="row">
              <div className="vendor-top col-md-6">
                <h5 className="title-v">{categoryType == 'Add' ? 'Add' : 'Edit'} Category (Level 1)</h5>
              </div>
              <div className="mb-6 col-md-6 text-end">
                {role == 'admin' && <button className="btn btn-theam" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <span className="mx-2">{categoryType == 'Add' ? 'Add' : 'Edit'} Category</span>
                  </span>
                </button>}
              </div>
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
              <div class="offcanvas-header border-bottom mb-4">
                <h5 id="offcanvasRightLabel" className="text-capatlise">{categoryType == 'Add' ? 'Add' : 'Edit'} Category (Level 1)</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Category Name<span className="required">*</span></label>
                  <input type="text" class="form-control" onChange={handleNameChange} id="categoryNameId" placeholder="Enter category Name" value={name} />
                  <span className="error">{nameError && 'Name field is required'}</span>
                </div>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Upload Image<span className="required">*</span></label>
                  <input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    id="contained-button-file"
                    className="form-control"
                    onChange={handleImageChange}
                  />
                  <span className="validation-mesgges">{imgError ? 'image is required' : ''}</span>
                </div>
              </div>
              <div class="offcanvas-footer border-top pt-4">
                <div className=" mb-4 d-flex justify-content-end mx-4">
                  <button className="btn btn-secondary px-3 mx-2" data-bs-dismiss={validate && "offcanvas"} onClick={() => addCategoryClick('Add')} type="button">{categoryType == 'Add' ? 'Add' : 'Update'} Category</button>
                  <button className="btn btn-theam px-5" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
              </div>
            </div>
            <hr className="mb-4"></hr>
            <AllCategories editCategoryClick={editCategoryClick} deleteCategory={deleteCategory} cat={cate} />
          </div>
        </section>
      </div>
    </>
  );
}
export default AddCategories;