
import { alpha } from '@material-ui/core';
import { Box, Button, CircularProgress, FormControl, LinearProgress, styled, Switch } from '@mui/material';
import { pink, purple } from '@mui/material/colors';
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import toast from 'react-hot-toast';
import { getAdminId, getAffilateAccessToken, getAffilateUserId } from '../../utills';
import { _get_task, _get_task_affilate, _get_task_collection } from '../../url';
// import { styled } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadModal from './component/FileUploadModal';
import Pagination from '../AdminPanel/component/affilateDashboard/Pagination';


function AllTask({ userInfo }) {
  const [open, setOpen] = React.useState(false);
  const [isPending, setisPending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);


  // pagination state
  const [selectedOption, setSelectedOption] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [remount, setRemout] = useState(false);
  const [tasks, setLabels] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [action, setAction] = useState('action');
  const [projectName, setProjectName] = useState([]);

  const [collection, setCollections] = useState([]);
  const [collectionLoading, setCollectionLoading] = useState(false);

  const [openUploadModal, setopen] = useState(false);


  useEffect(() => {
    const fetchLabels = async () => {
      try {
        setCollectionLoading(true);
        const response = await axios({
          url: _get_task_collection,
          method: 'post',
          headers: {
            authorization: `Bearer ${getAffilateAccessToken()}`,
          },
          data: { id: getAffilateUserId() }
        });
        setCollections(response.data.data); // Adjust based on your API response structure
      } catch (error) {
        console.error('Error fetching labels:', error.response.data.message);
        toast.error(error?.response?.data?.message || 'Failed to fetch collections.');
      } finally {
        setCollectionLoading(false);
      }
    };
    fetchLabels();
  }, [remount]);




  // handle change page data size
  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);
    // setCurrentPage(1);
  };

  const handleClickOpen = (data) => {
    setAction('Edit');
    setEditUser(data);
    setOpen(true);
  }

  const handleOpenFileUploadModal = (data) => {
    setEditUser(data);
    setopen(true);
  }

  const getData = async (data) => {
    setIsFetching(true);
    setProjectName(data?.labelName)
    try {
      const response = await axios({
        url: _get_task_affilate,
        method: 'post',
        headers: {
          authorization: `Bearer ${getAffilateAccessToken()}`,
        },
        data: { id: getAffilateUserId(), projectId: data?._id }
      });
      // const d = response.data.data;
      setLabels(response.data.data); // Adjust based on your API response structure
      setIsFetching(false);
    } catch (error) {
      console.error('Error fetching labels:', error?.response?.data?.message);
      toast.error(error?.response?.data?.message || 'Failed to fetch date.');
      setIsFetching(false);
    }
  }


  const label = { inputProps: { "aria-label": "Size switch demo" } };
  return (
    <>
      {isPending && <LinearProgress color="secondary" />}
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v"></h5>
              <div className="button-right-side">
                <div className="addBrands btn-theam p-2">
                  wallet - {userInfo?.walletAmount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <h5 className="title-v">All Project</h5>
          {
            collectionLoading && <CircularProgress color="secondary" />
          }
          <div className="row gap-2 mt-4" style={{ height: '300px', overflowY: 'auto' }}>

            {
              collectionLoading === false && collection?.map(data => (
                <div onClick={() => getData(data)} className="project-card col-sm-10 col-md-6 col-lg-3 card d-flex justify-content-center align-items-center " style={{ cursor: 'pointer', height: '150px', width: '150px' }}>
                  <h6 className="text-center w-75 text-wrap">{data?.labelName}</h6>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <section className="custom-cart-panel  mb-3">
       {projectName && <h3>Tasks of {projectName}</h3>} 
        <div className="d-flex flex-row justify-content-between">
          <Box className="d-flex align-items-center gap-2 w-25">
            <FormControl fullWidth>
              <input
                // value={codeVal}
                // onChange={(e) => setcode(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Search Affiliates"
                required
              />
            </FormControl>
          </Box>
          <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
            <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
              <div
                className="dataTables_length mx-n2"
                id="DataTables_Table_0_length"
              >
                <label>
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    className="form-select"
                    id="entries"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="70">70</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
              <div className="mb-6">
                {/* <button className="btn btn-theam mx-2" type="button" onClick={() => 
                    {
                        setOpen(!open);
                        setAction("Create")
                    }
                    }>
                  <span>Create Task</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="mlr-10 mb-4">
          {isFetching ? (
            "Fetching users..."
          ) : (
            <table className="tableGap">
              <thead className="text-center">
                <tr>
                  <th>Task Name</th>
                  <th>Project Name</th>
                  <th>Task URL</th>
                  <th>Date/Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  tasks?.length === 0 && <p className="p-2">No data found of this project</p>
                }
                {
                  tasks?.map(task => (
                    <tr key={task?._id} className="text-center">

                      <td>{task?.taskName}</td>
                      <td>{task?.taskTabel?.labelName}</td>
                      <td>{task?.taskUrl}</td>
                      <td>{new Date(task?.createdAt).toLocaleTimeString()}-{new Date(task?.createdAt).toLocaleDateString()}</td>
                      <td>
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                          className="btn-theam"
                          onClick={() => handleOpenFileUploadModal(task)}
                        >
                          Upload
                        </Button>
                      </td>
                    </tr>
                  ))
                }

                {/* {users?.map((data, index) => (
                  <tr key={data?._id}>
                    <td>{index + 1}.</td>
                    <td>{data?.categoryId?.category_name}</td>
                    <td>{data?.name}</td>
                    <td>{data?.affiliatePercentage}</td>
                    <td>
                      <PinkSwitch
                        {...label}
                        checked={data?.isAffiliateUser}
                        onChange={(event) => handleUpdateStatus(event,data?._id)}
                      />
                    </td>
                    <td className="d-flex items-center">
                      <button
                        className="edit-btn"
                        onClick={() => handleClickOpen(data)}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="trash-icon disabledBtn" onClick={() => handleDeleteUser(data)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          )}
        </div>
        <div className="d-flex justify-content-end">
         <div></div>
         <Pagination
         selectedOption={selectedOption}
         currentPage={currentPage}
         setCurrentPage={setCurrentPage}
         totalItems={totalItems}
         setTotalItems={setTotalItems}
        />
      </div>
      </section>
      {/* <CreateDrawer
          open={open}
          setOpen={setOpen}
          for="CREATE_TASK"
          setRemout={setRemout}
          action={action}
          editUser={editUser}
        />
        */

        
        <FileUploadModal
          open={openUploadModal}
          onClose={() => setopen(false)}
          editUser={editUser}
          setEditUser={setEditUser}
        />
      }
    </>
  )
}

export default AllTask