import React, { useEffect, useState } from "react";
// import banner from "../img/krizabanner1.png";
// import banner2 from "../img/krizaabanner2.png";
// import banner3 from "../img/krizaabanner3.png";
import icon1 from "../../img/Icon1.png";
import icon2 from "../../img/Icon2.png";
// import icon2 from "../../img/icon2.png";
import icon3 from "../../img/Icon3.png";
import icon4 from "../../img/Icon4.png";
import icon5 from "../../img/Icon5.png";
import icon6 from "../../img/Icon6.png";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { REACT_BASE_PATH, Asset_url } from "../../api";

const Hero = () => {
  const [allBanners, setAllBanners] = useState([]);
  const [currentPath, setCurrentPath] = useState('');

  const location = useLocation();
  const {categoryId} = useParams();
  useEffect(() => {
    if(location.pathname==="/"){
      setCurrentPath('home');
    }else{
      setCurrentPath(location.pathname.split('/')[1]);
    }
  },[location])

  useEffect(() => {
    const fetchHomeBanners = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/product/getBanner`, {
          category_id: location.pathname==='/' ? "0" : categoryId
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        // console.log(response.data);
        const notSponsoredBanner = response.data.data.filter((item) => (
          item.isSponsored===false
        ))
        setAllBanners(notSponsoredBanner);
      } catch (e) {
        console.error("Error in fetching home banners: ", e);
      }
    }

    fetchHomeBanners();
  }, [])

  return (
    <>
      {allBanners.length>0 && <section>
        <div
          id="carouselExample"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {
              allBanners.map((banner, index) => (
                <div key={index} className={`carousel-item ${index===0 ? 'active' : ''} `}>
                  <img src={`${Asset_url}${banner.banner_image}`} className="banner" alt={banner.banner_title} />
                </div>
              ))
            }
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>}

      {currentPath==='home' && <section className="sec-pad">
        <div className="container-fluid pay-row">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 col-6 prod-box">
              <div className="pay-box">
                <img src={icon1} alt="icon-1" className="pay-icon" />
                <p className="icon-p">
                  100% <br />
                  Payment Secured{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6 prod-box">
              <div className="pay-box">
                <img src={icon2} alt="icon-1" className="pay-icon" />
                <p className="icon-p">
                  Support lots <br />
                  of Payments
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6 prod-box">
              <div className="pay-box">
                <img src={icon3} alt="icon-1" className="pay-icon" />
                <p className="icon-p">
                  24hours/7days
                  <br /> Support
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6 prod-box">
              <div className="pay-box">
                <img src={icon4} alt="icon-1" className="pay-icon" />
                <p className="icon-p">
                  Free Delivery
                  <br /> with $50{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6 prod-box">
              <div className="pay-box">
                <img src={icon5} alt="icon-1" className="pay-icon" />
                <p className="icon-p">
                  Best Price
                  <br /> Guaranteed
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6 prod-box">
              <div className="pay-box">
                <img src={icon6} alt="icon-1" className="pay-icon" />
                <p className="icon-p">
                  Mobile <br />
                  Apps Ready
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>}
    </>
  );
};

export default Hero;
