import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField } from "@mui/material";
import { getAdminId } from "../../../../utills";
import axios from "axios";
import { _updateUserDetailURL } from "../../../../url";
import toast from "react-hot-toast";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minWidth: "30rem",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const countries = ["United States", "Canada", "United Kingdom", "India"];
const states = ["California", "Texas", "New York", "Ontario"];
const genders = ["Male", "Female", "Other"];

function UpdateProductModal({ title, open, handleClose,categories,editUser,_updateUser,isPending,setRemount }) {
  const [countries, setCountries] = React.useState([]);
  const [loading , setLoading] = React.useState(false);


  // State for form inputs
  const [formData, setFormData] = React.useState({
    category: "",
    affiliatePercentage: null,
    username: "",
    email: "",
    phoneNumber: "",
    country: "",
    state: "",
    city : "",
    gender: "",
    pincode: "",
  });

  React.useEffect(() => {
    if(editUser) {
      setFormData({
        category: "",
        affiliatePercentage: null,
        username: editUser?.name,
        email: editUser?.email,
        phoneNumber: editUser?.phone_number,
        country: editUser?.country,
        state: editUser?.state,
        city: editUser?.city,
        gender: editUser?.gender,
        pincode: editUser?.pincode,
      }) 
    }
     console.log('editUser',editUser)
  },[editUser])

  React.useEffect(() => {
    const fetchCountries = async () => {
      //(true);
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCountries(data);
        
      } catch (error) {
        // setError(error.message);
      } finally {
        // setLoading(false);
        //(false);
      }
    };
    if(open) fetchCountries();
  }, [open, setRemount]);
  


  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title === "Edit Product") {
      const payload = {
        id: getAdminId(),
        categoryId: formData.category || editUser?.categoryId?._id,
        affiliatePercentage: +formData.affiliatePercentage || editUser?.affiliatePercentage,
      };

      console.log("payload",payload)
      _updateUser(payload);
      handleClose();
    }

    if (title === "Edit User") {
      console.log("User Form Data:", {
        username: formData.username,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        gender: formData.gender,
        pincode: formData.pincode,
        id: getAdminId(),
        userId: editUser?._id,
      });
    
      // debugger;
      const data = {
        id: getAdminId(),
        userId: editUser?._id,
        name: formData.username,
        email: formData.email,
        phone_number: formData.phoneNumber,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        pincode: formData.pincode,
      }
     
      setLoading(true);
      try {
        const response = await axios.post(_updateUserDetailURL, data);
        console.log("_updateUser response", response.data.success)
        if (response?.data.success) {
          toast.success("User details updated successfully");  // Optional: Display success
          // Reset form fields after successful submission
          setFormData({
            category: "",
            affiliatePercentage: null,
            username: "",
            email: "",
            phoneNumber: "",
            country: "",
            state: "",
            city: "",
            gender: "",
            pincode: "",
          }) 
          setLoading(false);
          // setisPending(false);
          setRemount(prev => !prev);
          handleClose();
        }
      } catch (error) {
        toast.error("Failed to update user details");  // Optional: Display error
        console.error("There was an error!", error);
        setLoading(false);
        // setisPending(false)
      }
    }
     // Close modal after submission
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        >
        {loading && <LinearProgress color="secondary" /> }
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* Edit Product Form */}
          {title === "Edit Product" && (
            <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
              {/* Category */}
              <Box className="d-flex flex-column align-items-start gap-2">
                {/* <span>All Category</span> */}
                 <span>Category</span>
                 <h5 id="category-label bg-light">{editUser?.categoryId?.category_name}</h5>
              </Box>

              {/* Affiliate Percentage */}
              <Box className="d-flex flex-column align-items-start gap-2">
                <span>Affiliate Percentage</span>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    name="affiliatePercentage"
                    defaultValue={formData.affiliatePercentage || editUser?.affiliatePercentage}
                    value={formData.affiliatePercentage}
                    onChange={handleChange}
                    placeholder="Percentage %"
                    max="100"
                    required
                  />
                </FormControl>
              </Box>
            </form>
          )}

          {/* Edit User Form */}
          {title === "Edit User" && (
            <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
              {/* Username */}
              <Box className="d-flex flex-column align-items-start gap-2">
                <span>Username</span>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    name="username"
                    defaultValue={formData.username || editUser?.name}
                    value={formData.username}
                    onChange={handleChange}
                    placeholder="Enter your username"
                    required
                  />
                </FormControl>
              </Box>

              {/* Email */}
              <Box className="d-flex flex-column align-items-start gap-2">
                <span>Email</span>
                <FormControl fullWidth>
                  <TextField
                    type="email"
                    name="email"
                    defaultValue={formData.email || editUser?.email}
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                  />
                </FormControl>
              </Box>

              {/* Phone Number */}
              <Box className="d-flex flex-column align-items-start gap-2">
                <span>Phone Number</span>
                <FormControl fullWidth>
                  <TextField
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    defaultValue={formData.phoneNumber || editUser?.phone_number}
                    onChange={handleChange}
                    placeholder="Enter your phone number"
                    required
                  />
                </FormControl>
              </Box>

              {/* Country */}
              <Box className="d-flex flex-column align-items-start gap-2">
                <span>Country</span>
                <FormControl fullWidth>
                  <InputLabel id="country-label">Select Country</InputLabel>
                  <Select
                    labelId="country-label"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  >
                    {countries?.map((country, index) => (
                      <MenuItem key={country?.name?.common} value={country?.name?.common}>
                        {country?.name?.common}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* State */}
              {/* <Box className="d-flex flex-column align-items-start gap-2">
                <span>State</span>
                <FormControl fullWidth>
                  <InputLabel id="state-label">Select State</InputLabel>
                  <Select
                    labelId="state-label"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  >
                    {cities.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box> */}

           <Box className="d-flex flex-column align-items-start gap-2">
                <span>State</span>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    name="state"
                    value={formData.state}
                    defaultValue={formData.state || editUser?.state}
                    onChange={handleChange}
                    placeholder="Enter your state"
                    required
                  />
                </FormControl>
              </Box>
     
              <Box className="d-flex flex-column align-items-start gap-2">
                <span>City</span>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    name="city"
                    value={formData.city}
                    defaultValue={formData.city || editUser?.city}
                    onChange={handleChange}
                    placeholder="Enter your city"
                    required
                  />
                </FormControl>
              </Box>

              


              {/* Pincode */}
              <Box className="d-flex flex-column align-items-start gap-2">
                <span>Pincode</span>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    name="pincode"
                    value={formData.pincode}
                    defaultValue={formData.pincode || editUser?.pincode}
                    onChange={handleChange}
                    placeholder="Enter your pincode"
                    required
                  />
                </FormControl>
              </Box>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            size="medium"
            style={{ background: "#2f0a34" , color : '#fff' }}
            onClick={handleSubmit}
          >
           {isPending ? <CircularProgress size="30px" /> : 'Update'} 
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}


export default React.memo(UpdateProductModal)