import React, { useContext, useEffect } from "react";
import Header from "../header/Header";
import Footer from "../header/Footer";
import { useState } from "react";
import { REACT_BASE_PATH } from "../../api";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
// import('../../components/WebPanel/WebPanelRoutes.css');
import Cookies from 'js-cookie'
import { CartContext } from "../../components/WebPanel/cart/CartContext";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from 'jwt-decode';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { state, dispatch } = useContext(CartContext);

  useEffect(() => {
    const userToken = Cookies.get('krizaar-user');
    const userId = localStorage.getItem('userId');
    if (userToken && userId) {
      navigate("/");
      return;
    }
  }, [])

  const syncCartWithDatabase = async (cartState, cartProducts, userId, token) => {
    const dbProductIds = cartProducts.map(product => product.product_id?._id);
    const productsToAdd = cartState.filter(item => !dbProductIds.includes(item.id));
    for (const product of productsToAdd) {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/cart/user/add/product/cart`,
          {
            id: userId,
            product_id: product.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (e) {
        console.error('Error adding product to database:', e);
      }
    }
    navigate("/");
  }

  const submitData = async () => {
    if (!email) {
      toast.warning('Please enter email')
      return;
    }
    if (!password) {
      toast.warning('Please enter password')
      return;
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/auth/user/login`, {
        user: {
          user_contact: email,
          password: password,
        },
      });
      if (response.status === 200) {
        Cookies.set('krizaar-user', response.data.accessToken, { expires: 1 / 3 });
        localStorage.setItem("userId", response.data.userid);

        // navigate('/')
        try {
          const cartResponse = await axios.post(`${REACT_BASE_PATH}/cart/user/cart/product/list`, {
            id: response.data.userid,
          }, {
            headers: {
              Authorization: `Bearer ${response.data.accessToken}`,
            },
          });

          const cartProducts = cartResponse.data.addToCartProducts;

          cartProducts.forEach(product => {
            if (product.product_id) {
              const productInformation = product.product_id;
              const existingProduct = state.cart.find(item => item.id === productInformation._id);
              if (!existingProduct) {
                const productInfo = {
                  id: productInformation._id,
                  image: productInformation.product_banner_image,
                  name: productInformation.product_name,
                  price: productInformation.price,
                  offer_price: productInformation.offer_price,
                  seller: productInformation.vendor_details,
                  quantity: productInformation.quantity ? productInformation.quantity : 1,
                };

                dispatch({ type: 'ADD_TO_CART', payload: productInfo });
              }
            }
          });

          await syncCartWithDatabase(state.cart, cartProducts, response.data.userid, response.data.accessToken);
        } catch (e) {
          console.error('Error fetching cart items:', e);
        }
      };
    } catch (error) {
      if (error.response?.data?.status === 403) {
        localStorage.setItem("unverifiedUserId", error.response.data.user_id);
        localStorage.setItem("unverifiedUserEmail", email);
        toast.error(error.response.data.message);
        navigate('/otp-verification')
      } else if (error.response?.data?.status === 401) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error('Invalid email or password')
      }
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleGoogleLogin = async (response) => {
    const { credential } = response;
    const { email, given_name, picture } = jwtDecode(credential);
    // console.log(response)

    try {
      const result = await axios.post(`${REACT_BASE_PATH}/auth/user/google/login`, {
        email: email,
        first_name: given_name,
        password: "12345678",
        token: credential,
        // picture
      })
      // console.log(result)
      if (result.status === 200) {
        Cookies.set('krizaar-user', result.data.accessToken, { expires: 1 / 3 });
        localStorage.setItem("userId", result.data.userid);
        navigate('/')
      }
    } catch (e) {
      if (e.response?.status === 422) {
        toast.warning('You are using a different method. Please use the method for login, which you have used while registering.')
      }
      toast.error(e.response?.data?.message || "Some Error occured")
    }
  }
  const handleError = async (error) => {
    toast.error("Some Error occured")
    // console.log(error)
  }

  return (
    <>
      <section>
        <div className="container">
          <div className="row mb-5">
            <div className="signin-box">
              <div className="signin-box1">
                <h4 className="text-center mt-5">Krizaar.com</h4>
                <div className="sign-form">
                  <h5 className="mb-4">Sign In</h5>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="username"
                    name="password"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="email-input"
                    placeholder=""
                    required
                  />
                  <label htmlFor="password">Password</label>
                  <div className="password-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className=""
                      placeholder=""
                      required
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  <div className="forgot-password text-end">
                    <NavLink to='/forgot-password' className='text-white'>
                      Forgot Password?
                    </NavLink>
                  </div>
                  <br />
                  <div className="sbtn-box">
                    <button
                      type="submit"
                      className="sub-btn"
                      onClick={submitData}
                    >
                      Sign In
                    </button>

                  </div>
                  <div className="or-div text-center my-4">
                    or
                  </div>
                  <div className="sbtn-box mb-3">
                    <GoogleLogin
                      onSuccess={handleGoogleLogin}
                      onError={handleError}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-4" />
            <div className="amazon-box">
              <span className="amazon">Don't have an account?</span>
            </div>
            <div className="create-acc-box">
              <NavLink to="/signup">
                <button className="create-acc">
                  Register here!
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signin;
