import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth(false);
  const vendor = JSON.parse(localStorage.getItem('vendor'))
  const { role, is_kyc_approved, is_kyb_approved } = vendor;

  if(role !== 'admin'){
    if (role === "dropShipper" && !is_kyc_approved) {
      return <Navigate to="/vendorlogin" replace />;
    } else if (role === "seller" && !is_kyc_approved && !is_kyb_approved) {
      return <Navigate to="/vendorlogin" replace />;
    }else{
      return children;
    }
  }else{
    return children;
  }
  
  // if (!isAuthenticated && ((vendor?.role == 'dropShipper' && vendor.is_kyc_approved === false) ||
  //   (vendor?.role === 'seller' && vendor.is_kyb_approved === false && vendor.is_kyc_approved === false))) {
  //   return <Navigate to="/vendorlogin" replace />;
  // }
  // if (!isAuthenticated){
  //   return <Navigate to="/vendorlogin" replace />;
  // }

  
};

export default ProtectedRoute;
