import { Box, Paper, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'



  
function AffilateEarning({Vendordata,ladger}) {
  const [lifeTimeEar, setEach] = useState(0);
  useEffect(() => {
     console.log(ladger)
     if(ladger) {
      //  debugger;
        let data = ladger;
        data = data?.map(d => d?.amount);
        const earning = data?.reduce((acc,curr)=> {
           return acc+curr;
        }, 0);
        setEach(earning)
     }
  },[ladger])
  return (
    <Box
      sx={{
        width : '80%',
        margin : 'auto',
        p: 2,
        borderRadius: 2,
        bgcolor: 'background.default',
        display: 'grid',
        gridTemplateColumns: { md: '1fr 1fr' },
        gap: 2,
      }}
    >
      <div className="earning-card">
         <p>Current Earning</p>
         <h2>${Vendordata?.walletAmount || '0.00'}</h2>
      </div>
      <div className="earning-card">
         <p>Life Time Earning</p>
         <h2>${lifeTimeEar} .00</h2>
      </div>
    </Box>
  )
}

export default AffilateEarning