import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { REACT_BASE_PATH, Asset_url } from "../../../api";


const PayPalPaymentgateway = ({ grandTotal, paypalClientId, placedOrderDetails, setPaymentStatus, setPaymentError, setIsPaymentModalOpen }) => {
    const navigate = useNavigate()
    const handleCreateOrder = async () => {
        try {
            setPaymentStatus('processing');
            const { data } = await axios.post(`${REACT_BASE_PATH}/create-order`, {
                currencyTpe: 'USD',
                amount: grandTotal.toFixed(2)
            });
            // console.log(data)
            return data.id;
        } catch (error) {
            setPaymentStatus('failed');
            setPaymentError('Error in creating order');
            console.error("Error creating order:", error);
            throw error;
        }
    };

    const handleApproveOrder = async (data) => {
        try {
            // Call your backend capture-order API
            const response = await axios.post(`${REACT_BASE_PATH}/capture-order`, {
                orderID: data.orderID,
            });
            setPaymentStatus('success');
            setTimeout(() => {
                navigate(`/order-details/${placedOrderDetails.order._id}`);
            }, 6000);
        } catch (error) {
            setPaymentStatus('failed');
            setPaymentError('Some server error occured');
            console.error("Error capturing order:", error);
            throw error;
        }
    };

    if(!paypalClientId){
        return <p>
            Client id not available
        </p>
    }

    return (
        <PayPalScriptProvider options={{ "client-id":  paypalClientId }}>
            <PayPalButtons
                createOrder={handleCreateOrder}
                onApprove={handleApproveOrder}
            />
            <div className='d-flex justify-content-center gap-5 mt-4'>
                <button type="button" className="checkout__btn checkout__confirm-btn" onClick={() => setIsPaymentModalOpen(false)}>
                    Cancel
                </button>
            </div>
        </PayPalScriptProvider>
    );
};

export default PayPalPaymentgateway;
