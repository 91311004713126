
import { alpha } from '@material-ui/core';
import { Box, FormControl, LinearProgress, styled, Switch } from '@mui/material';
import { pink, purple } from '@mui/material/colors';
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import CreateDrawer from './component/CreateDrawer';
import { getAdminId } from '../../../../utills';
import axios from 'axios';
import toast from 'react-hot-toast';
import { _get_task } from '../../../../url';
import DeleteModal from './component/DeleteModal';

function CreateTask() {
    const [open, setOpen] = React.useState(false);
    const [isPending, setisPending] =  useState(false);
    const [isFetching, setIsFetching] =  useState(false);
    
   // pagination state
   const [selectedOption, setSelectedOption] = useState(10);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalItems, setTotalItems] = useState(0);

   const [remount, setRemout] = useState(false);
   const [tasks, setLabels] = useState([]);
   const  [editUser, setEditUser] = useState(null);
   const  [action, setAction] = useState('action');

   const [openDelteModal , setOpenDelteModal] = useState(false);

   useEffect(() => {
    const fetchLabels = async () => {
        try {
            const response = await axios.post(_get_task, {id : getAdminId()});
            setLabels(response.data.data); // Adjust based on your API response structure
        } catch (error) {
            console.error('Error fetching labels:', error);
            toast.error('Failed to fetch labels.');
        }
    };
    fetchLabels();
}, [remount]);


console.log("tasks",tasks)


    
      // handle change page data size
  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);
    // setCurrentPage(1);
  };

  const handleClickOpen = (data) => {
    setAction('Edit');
    setEditUser(data);
    setOpen(true);
  }

  const handleDeleteUser = (data) => {
    setEditUser(data);
    setOpenDelteModal(true);
  }

  const resetData = (cb) => {
     cb();
  }

  const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: purple[800],
          '&:hover': {
            backgroundColor: alpha(purple[800], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor:purple[800],
        },
      }));
    
const label = { inputProps: { "aria-label": "Size switch demo" } };
  return (
    <>
      {isPending && <LinearProgress color="secondary" /> }
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">All Task</h5>
              <div className="button-right-side">
                <div className="addBrands">{/* <BrandPopup /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className="custom-cart-panel  mb-3">
        <div className="d-flex flex-row justify-content-between">
          <Box className="d-flex align-items-center gap-2 w-25">
            <FormControl fullWidth>
              <input
                // value={codeVal}
                // onChange={(e) => setcode(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Search Affiliates"
                required
              />
            </FormControl>
          </Box>
          <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
            <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
              <div
                className="dataTables_length mx-n2"
                id="DataTables_Table_0_length"
              >
                <label>
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    className="form-select"
                    id="entries"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="70">70</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
              <div className="mb-6">
                <button className="btn btn-theam mx-2" type="button" onClick={() => 
                    {
                        setOpen(!open);
                        setAction("Create");
                    }
                    }>
                  <span>Create Task</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="mlr-10 mb-4">
          {isFetching ? (
            "Fetching users..."
          ) : (
            <table className="tableGap">
              <thead>
                <tr>
                  <th>Task Name</th>
                  <th>Project Name</th>
                  <th>Project URL</th>
                  <th>Date/Time</th>
                  {/* <th>Status</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                     tasks?.map(task => (
                        <tr key={task?._id}>
                    
                        <td>{task?.taskName}</td>
                        <td>{task?.taskTabel?.labelName}</td>
                        <td>{task?.taskUrl}</td>
                        <td>{new Date(task?.createdAt).toLocaleTimeString()}-{new Date(task?.createdAt).toLocaleDateString()}</td>
                        {/* <td>
                          <PinkSwitch
                            {...label}
                            // checked={task?.isAffiliateUser}
                            // onChange={(event) => handleUpdateStatus(event,"data"?._id)}
                          />
                        </td> */}
                        <td className="d-flex items-center">
                          <button
                            className="edit-btn"
                            onClick={() => handleClickOpen(task)}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button className="trash-icon disabledBtn" 
                            onClick={
                            () => handleDeleteUser(task)
                            
                            }>
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>   
                     ))
                }
                
                {/* {users?.map((data, index) => (
                  <tr key={data?._id}>
                    <td>{index + 1}.</td>
                    <td>{data?.categoryId?.category_name}</td>
                    <td>{data?.name}</td>
                    <td>{data?.affiliatePercentage}</td>
                    <td>
                      <PinkSwitch
                        {...label}
                        checked={data?.isAffiliateUser}
                        onChange={(event) => handleUpdateStatus(event,data?._id)}
                      />
                    </td>
                    <td className="d-flex items-center">
                      <button
                        className="edit-btn"
                        onClick={() => handleClickOpen(data)}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="trash-icon disabledBtn" onClick={() => handleDeleteUser(data)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          )}
        </div>
        </section>
        <CreateDrawer
          open={open}
          setOpen={setOpen}
          for="CREATE_TASK"
          setRemout={setRemout}
          action={action}
          editUser={editUser}
          resetData={resetData}
        />
         <DeleteModal 
          for="CREATE_TASK"
          open={openDelteModal}
          onClose={() => setOpenDelteModal(false)}
          editUser={editUser}
          setRemount={setRemout}
        />
    </>
  )
}

export default CreateTask