import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from '../AdminPanel/component/Dashboard';
import Header from "../AdminPanel/component/Header";
import Sidebar from "../AdminPanel/component/Sidebar";
import AllBrands from "../AdminPanel/component/brands/AllBrands"
import AddBrands from "../AdminPanel/component/brands/AddBrands";
import AddCategories from "../AdminPanel/component/categories/AddCategories";
import AddSubCategories from "../AdminPanel/component/sub-categories/AddSubCategories";
import AddSubSubCategories from "../AdminPanel/component/sub-sub-categories/AddSubSubCategories";
import Allproducts from "../AdminPanel/component/products/Allproducts";
import Addnewproduct from "../AdminPanel/component/products/Addnewproduct";
import AllAttributes from "../AdminPanel/component/AllAttributes";
import AllOrders from "../AdminPanel/component/orders/AllOrders";
import OrderDetails from "../AdminPanel/component/orders/orderDetails";
import PendingOrders from "../AdminPanel/component/orders/pendingOrders";
import CancelOrders from "../AdminPanel/component/orders/cancelOrders";
import ConfirmedOrders from "../AdminPanel/component/orders/confirmedOrder";
import AddBanners from "../AdminPanel/component/banners/addBanners";
import AllVendors from "../AdminPanel/component/vendors/allVendors";
import VendorDetails from "../AdminPanel/component/vendors/vendorDetails";
import AllUsers from "../AdminPanel/component/users/allUsers";
import UserrDetails from "../AdminPanel/component/users/userDetails";
import ReturnOrders from "../AdminPanel/component/orders/returnOrder";
import ReturnDetails from "../AdminPanel/component/orders/returnDetails";
import Shipping from "../AdminPanel/component/orders/shipping";
import OffersList from "../AdminPanel/component/offers/offers";
import ProfileAdmin from "../AdminPanel/component/ProfileAdmin";
import ChangePassword from "../AdminPanel/component/ChangePassword";
import ProductTaxes from "../AdminPanel/component/ProductTaxes";
import ImportProducts from "../AdminPanel/component/aliExpress/ImportProducts";
import Settings from "../AdminPanel/component/settings/Settings";
import NotFound from "../../componenth/common/NotFound";
import AllProducts from "../AdminPanel/component/affilateDashboard/AllProducts";
import Ladger from "../AdminPanel/component/affilateDashboard/Ladger";
import AffilateUser from "../AdminPanel/component/affilateDashboard/AffilateUser";
import CreateTaskLable from "../AdminPanel/component/airdrop/CreateTaskLable";
import CreateTask from "../AdminPanel/component/airdrop/CreateTask";
import TaskHistory from "../AdminPanel/component/airdrop/component/TaskHistory";
import AffiliateDetails from "../AdminPanel/component/affilateDashboard/AffiliateDetails";
import WithrawlPage from "../AdminPanel/component/affilateDashboard/WithrawlPage";
import { isJwtExpired } from "jwt-check-expiration";
import toast from "react-hot-toast";
import { getAffialteToken } from "../../utills";

const AdminPanelRoutes = () => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };
    const navigate = useNavigate();

    useEffect(() => {
        if(isJwtExpired(getAffialteToken())) {
          toast.error("Token Has Expired,you need to login again");
          setTimeout(() => {
            navigate('/vendorlogin');  
          },[])
        }
      },[navigate])
    return (
        <>
            <div className="app admin-panel-body">
                <Header collapsed={collapsed} onToggle={toggleSidebar} />
                <div className="main-container">
                    <Sidebar
                        collapsed={collapsed}
                    />
                    <div className="main-content">
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/allbrands" element={<AllBrands />} />
                            <Route path="/addbrand" element={<AddBrands />} />
                            <Route path="/categories" element={<AddCategories />} />
                            <Route path="/subcategories" element={<AddSubCategories />} />
                            <Route path="/subsubcategories" element={<AddSubSubCategories />} />
                            <Route path="/allproducts" element={<Allproducts />} />
                            <Route path="/addproduct" element={<Addnewproduct editType={'Add'} />} />
                            <Route path="/editproduct" element={<Addnewproduct editType={'Edit'} />} />
                            <Route path="/attributes" element={<AllAttributes />} />
                            <Route path="/allOrders" element={<AllOrders />} />
                            <Route path="/OrderDetails" element={<OrderDetails />} />
                            <Route path="/returnOrders" element={<ReturnOrders />} />
                            <Route path="/returnDetails" element={<ReturnDetails />} />
                            <Route path="/shipping" element={<Shipping />} />
                            <Route path="/pendingOrders" element={<PendingOrders />} />
                            <Route path="/cancelOrders" element={<CancelOrders />} />
                            <Route path="/completedOreders" element={<ConfirmedOrders />} />
                            <Route path="/addBanner" element={<AddBanners />} />
                            <Route path="/allvendors" element={<AllVendors />} />
                            <Route path="/vendorDetails" element={<VendorDetails />} />
                            <Route path="/allusers" element={<AllUsers />} />
                            <Route path="/userDetails" element={<UserrDetails />} />
                            <Route path="/offers" element={<OffersList/>} />
                            <Route path="/profile" element={<ProfileAdmin/>} />
                            <Route path="/productTaxes" element={<ProductTaxes/>} />
                            <Route path="/changePassword" element={<ChangePassword/>} />
                            <Route path="/import_product" element={<ImportProducts/>} />
                            <Route path="/settings" element={<Settings/>} />
                            <Route path="/all_products" element={<AllProducts/>} />
                            <Route path="/history" element={<Ladger/>} />
                            <Route path="/affiliate_defails" element={<AffilateUser/>} />
                            <Route path="/affilateUserDetails" element={<AffiliateDetails/>} />
                            <Route path="/create_task_label" element={<CreateTaskLable/>} />
                            <Route path="/create_task" element={<CreateTask/>} />
                            <Route path="/task_history" element={<TaskHistory/>} />
                            <Route path="/affiliate_withdrawal_history" element={<WithrawlPage/>} />
                            <Route path="/affiliate_withdrawal_requests" element={<WithrawlPage/>} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>   
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminPanelRoutes;
