import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const ElectronicsCommunicationPolicy = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className='terms-of-service my-5'>
            <h1 className="my-3">Electronic Communications Policy</h1>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div style={{ textAlign: 'justify' }}>Krizaar is required to provide certain information to you in writing. This policy explains how we will send that information to you electronically. By using any of Krizaar’s services, you agree to this policy as part of our Terms of Use.</div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>1. Types of Information</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>Krizaar will send important messages related to your use of its services, such as terms, policies, user agreements, billing statements, transaction details, privacy disclosures, tax forms, and other legal documents. All of this information will be delivered electronically, referred to as "Electronic Communications."
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>2. Consent to Electronic Delivery</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>By agreeing to this policy, you consent to receive all required communications electronically. This includes legal terms and disclosures. Your consent to electronic agreements holds the same legal effect as physically signing a document. For instance, clicking a button that indicates agreement to terms on the Krizaar website has the same impact as signing a paper contract.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>3. Methods of Delivery</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>Krizaar may send Electronic Communications through:
                      <ol>
                        <li>•	Email to the address associated with your Krizaar account,</li>
                        <li>•	Posting on the Krizaar website or mobile apps, or</li>
                        <li>•	Providing access through a link in an email.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>4. Technical Requirements</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>To receive Electronic Communications, you will need:
                      <ol>
                        <li>•	A computer or mobile device,</li>
                        <li>•	An internet connection,</li>
                        <li>•	A browser supporting 128-bit encryption and cookies,</li>
                        <li>•	An active email address, and</li>
                        <li>•	Sufficient storage or a printer to save or print the communications.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>5. Withdrawing Consent</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>You may choose to stop receiving Electronic Communications by updating your account or contacting Krizaar. Processing this request may take up to three months. If you withdraw your consent, Krizaar may restrict your access to services and may charge a fee for sending paper copies of communications. Ensure your mailing address is up-to-date if requesting paper copies.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>6. Updating Contact Information</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>It's important to keep your contact details in your Krizaar account up to date to avoid missing important communications. Krizaar is not responsible if you fail to receive communications due to outdated information.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ElectronicsCommunicationPolicy;
