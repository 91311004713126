import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { REACT_BASE_PATH, Asset_url } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { deleteProductMessage } from '../ResponseMessage'
import SnackBar from "../../helper/SnackBar";
import { exportCSV } from "../../helper/utility";
import { formatDate, formatDateTime } from "../../helper/DateTimeFormat";
import Pagination from "../Pagination";

const Allproducts = () => {
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [type, setType] = useState('');
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [openTost, setopenTost] = useState(false);
  const [tostMessage, setostMessage] = useState('');


  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);
    setCurrentPage(1);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const vendor = JSON.parse(localStorage.getItem('vendor'))
    const { vendorid, role } = vendor;
    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/product/getallproductsdetails`,
        {
          page: currentPage,
          vendorId: role === 'admin' ? "0" : vendorid,
          pagesize: selectedOption, // no of item
        }
      );
      setData(response.data.data);
      setTotalItems(response.data.totalItems); // Update total items
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, selectedOption]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const totalPages = Math.ceil(totalItems / selectedOption);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const editClick = (editData) => {
    navigate('../editproduct/', { state: editData })
  }

  const deleteProductList = async (id) => {
    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/product/delete/productDelete`,
        {
          product_id: id,
        }
      );
      setopenTost(true);
      setType('success');
      setostMessage(deleteProductMessage);
      fetchData()
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  const navigateAddProduct = () => {
    navigate('../addproduct')
  }

  return (
    <section id="admin-main-content">
      <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
      <div className="container-fuild">
        <div className="row">
          <div className="vendor-top">
            <h5 className="title-v">All Product</h5>
          </div>
        </div>
        <div className="row">
          <div className="card-header d-flex border-top rounded-0 flex-wrap py-0 flex-column flex-md-row align-items-start table-header-page">
            <div className="me-5 ms-n4 pe-5 mb-n6 mb-md-0">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Product"
                    aria-controls="DataTables_Table_0"
                  />
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
              <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                <div className="dataTables_length mx-n2" id="DataTables_Table_0_length">
                  <label>
                    <select
                      name="DataTables_Table_0_length"
                      aria-controls="DataTables_Table_0"
                      className="form-select"
                      id="entries"
                      value={selectedOption}
                      onChange={handleChange}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="70">70</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
                <div className="mb-6">
                  <button className="btn btn-theam mx-2" onClick={() => exportCSV(data[0])} type="button">
                    <span>Export CSV</span>
                  </button>
                  <button className="btn btn-theam" type="button" onClick={navigateAddProduct}>
                    <span>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      <span className="mx-2">Add Product</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="mlr-10 table-responsive mb-4">
              <table ref={tableRef}>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>SKU</th>
                    <th>Price</th>
                    <th>Brand Name</th>
                    <th>Category</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 && data.map((item, index) => {
                    return (
                      <tr key={item._id + index}>
                        <td>
                          <img
                            src={item?.product_banner_image?.startsWith('http')
                              ? item.product_banner_image
                              : `${Asset_url}${item?.product_banner_image}`
                            }
                            alt="product"
                            width="50"
                            height="50"
                          />
                        </td>
                        <td>{item.product_name}</td>
                        <td>{item.product_squ_id}</td>
                        <td>
                          {item.price}
                        </td>
                        <td>{item.brand_details?.brand_name}</td>
                        <td>{item.category_details_level0?.category_name}</td>
                        <td>{formatDate(item.createdAt)}</td>
                        <td>
                          <div className="btn-group border-0">
                            <button className="edit-btn" onClick={() => editClick(item)}>
                              <i className="fa fa-edit"></i>
                            </button>
                            <button className="trash-icon" onClick={() => deleteProductList(item._id)}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-sm-12 col-md-6 mt-2">
            <div className="dataTables_info">
              Displaying {currentPage} to {Math.min(currentPage * selectedOption, totalItems)} of {totalItems} entries
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="dataTables_paginate">
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Allproducts;
