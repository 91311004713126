import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../header/Header";
import Footer from "../header/Footer";
import { REACT_BASE_PATH } from "../../api";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'
import { toast } from "react-toastify";

// import('../../components/WebPanel/WebPanelRoutes.css');

const OTPVerification = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = Cookies.get('krizaar-user');
    const userId = localStorage.getItem('userId');
    if (userToken && userId) {
      navigate("/");
      return;
    }
  }, [])

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");

  const [user, setUser] = useState({});
  // console.log(user, "-----------------------------------------------------");

  const submitData = () => {
    if (email && password) {
      axios
        .post(`${REACT_BASE_PATH}/auth/user/registration`, {
          user: {
            user_contact: email,
            password: password,
          },
        })
        .then((resp) => {
          console.log(resp);
          setUser(resp.data);
        })
        .catch((err) => {
          console.log("error", err.message);
        });
    }
  };

  const verifyEmail = () => {
    if (otp) {
      // console.log("Verifying OTP");
      const userId = localStorage.getItem('unverifiedUserId');
      const email = localStorage.getItem('unverifiedUserEmail');
      if(!userId && !email){
        navigate('/signup')
        return;
      }
      axios
        .post(`${REACT_BASE_PATH}/auth/user/otpvarification`, {
          user: {
            user_contact: email,
            userId: userId,
            otp: otp,
          },
        })
        .then((resp) => {
          // console.log(resp, "OTP Verification Response");
          localStorage.removeItem('unverifiedUserId')
          localStorage.removeItem('unverifiedUserEmail')
          toast.success('Your otp have been verified')
          navigate('/login')
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
          // console.log("Error during OTP verification", err);
        });
    }
  };

  const handleResendOTP = () => {
    const userId = localStorage.getItem('unverifiedUserId');
    const email = localStorage.getItem('unverifiedUserEmail');
    if(!userId &&!email){
      navigate('/signup')
      return;
    }
    axios
     .post(`${REACT_BASE_PATH}/auth/user/otpresend`, {
        user: {
          user_contact: email,
          userId: userId,
        },
      })
     .then((resp) => {
        toast.success('OTP has been resent successfully')
      })
     .catch((error) => {
        toast.error(error.response?.data?.message || "Some server error occured");
        // console.log("Error during OTP resend", err);
      });
  }

  return (
    <>
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="signin-box">
              <div className="signin-box1">
                <h4 className="text-center mt-4">Krizaar.com</h4>
                <div className="sign-form">
                  <h5 className="mb-4">Authentication Required</h5>
                  <p>
                    We’ve sent a One Time Password (OTP) to the mobile
                    number/Email above. Please enter it to complete
                    verification.
                  </p>
                  <label htmlFor="otp">Enter OTP</label>
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    className="email-input"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                  <br />
                  <br />
                  <div className="sbtn-box">
                    <button onClick={verifyEmail} className="sub-btn">
                      Verify
                    </button>
                  </div>
                  <div className="resend-otp">
                    <button onClick={handleResendOTP}>Resend OTP</button>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-4" />
            <div className="amazon-box">
              <span className="amazon">or</span>
            </div>
            <div className="create-acc-box">
              <NavLink to="/signin">
                <button className="create-acc">
                  Login to your Krizaar account
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OTPVerification;
