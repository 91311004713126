import React from "react";

const Sidebar = ({ selectedCategory, setSelectedCategory }) => {
  const categories = [
    { id: "platform-overview", label: "Platform Overview" },
    { id: "seller-onboarding", label: "Seller Onboarding" },
    { id: "international-selling", label: "International Selling" },
    { id: "payments", label: "Payments" },
    { id: "shipping-logistics", label: "Shipping & Logistics" },
    { id: "security", label: "Security" },
    { id: "return-pickup", label: "Return & Pickup" },
    { id: "refund-related", label: "Refund Related" },
    { id: "order-tracking", label: "Order Tracking" },
    { id: "order-cancellation", label: "Order Cancellation" }
  ];

  return (
    <div className="faq-sidebar">
      <h2>FAQs</h2>
      <ul>
        {categories.map((category) => (
          <li
            key={category.id}
            className={selectedCategory === category.id ? "active" : ""}
            onClick={() => setSelectedCategory(category.id)}
          >
            {category.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
