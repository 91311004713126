import React, { useState } from "react";
import Sidebar from "./Sidebar";
import FAQContent from "./FAQContent";
import "./faq.css";

const FAQ = () => {
  const [selectedCategory, setSelectedCategory] = useState("platform-overview");

  return (
    <div className="container my-5">
      <div className="faq-page">
        <Sidebar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <FAQContent selectedCategory={selectedCategory} />
      </div>
    </div>
  );
};

export default FAQ;
