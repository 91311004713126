import React, { useState } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
  Snackbar,
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
// import { LinkIcon } from '@lucid-ui/icons'; // Example lucid icon

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const LinkModal = ({ linkData,open, handleClose }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopy = () => {
    // setOpenSnackbar(true);
    toast.success('Link Coppied')
  };
   
  const link = `${window.location.origin}/singleproduct`+linkData?.link

 
  


  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2">
          Generated Link
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <p className="w-100 text-truncate">{link}</p>
          <CopyToClipboard text={link} onCopy={handleCopy}>
            <Button  className="text-white btn card-header-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-copy"><rect width="14" height="14" x="8" y="8" rx="2" ry="2"/><path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"/></svg> Copy
            </Button>
          </CopyToClipboard>
        </Box>
      </Box>
{/* 
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="Link copied to clipboard!"
      /> */}
    </Modal>
  );
};

export default LinkModal;
